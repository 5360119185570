<ng-http-loader
  [backdrop]="false"
  [backgroundColor]="'#00ffd1'"
  [debounceDelay]="100"
  [extraDuration]="300"
  [minDuration]="300"
  [opacity]="0.6"
  [backdropBackgroundColor]="'#00ffd1'"
  spinner="sk-three-bounce"
></ng-http-loader>
<div class="outer">
  <!-- Filter panel starts here -->
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          Filter
          <mat-icon>filter_list</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="flex-start">
        <!-- From date -->
        <div>
          <mat-form-field class="datePickerForm" appearance="standard">
            <mat-label>Startdatum</mat-label>
            <input
              matInput
              [value]="fromDate"
              [matDatepicker]="startdatePicker"
              min="2016-01-01"
              [max]="getMaxDate()"
              (dateChange)="onChangeFromDate($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startdatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #startdatePicker
              [startAt]="fromDate"
            ></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- To date -->
        <div>
          <mat-form-field class="datePickerForm" appearance="standard">
            <mat-label>Slutdatum</mat-label>
            <input
              matInput
              [value]="toDate"
              [matDatepicker]="enddatePicker"
              min="2016-01-01"
              [max]="getMaxDate()"
              (dateChange)="onChangeToDate($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="enddatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #enddatePicker [startAt]="toDate"></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Transaction type -->
        <div>
          <mat-form-field class="transactionTypeDropDown" appearance="standard">
            <mat-label>Visa typ</mat-label>
            <mat-select
              class="select-font-size"
              [(value)]="selectedType"
              (selectionChange)="onSelectType($event)"
            >
              <mat-select-trigger>{{
                filterType.viewValue
              }}</mat-select-trigger>
              <mat-option
                class="transactionTypeOption"
                *ngFor="let transactionType of globalVariables.transactionTypes"
                [value]="transactionType.value"
              >
                <mat-icon
                  *ngIf="transactionType.icon_type == 'ico'"
                  class="transactionTypeOptionIcon"
                  >{{ transactionType.icon }}</mat-icon
                >
                <mat-icon
                  *ngIf="transactionType.icon_type == 'svg'"
                  [svgIcon]="transactionType.icon"
                  class="transactionTypeOptionIcon"
                ></mat-icon
                >{{ transactionType.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Emplyee select (admin) -->
        <div>
          <mat-form-field
            class="employeeDropDown"
            *ngIf="globalVariables.isLoggedinUserManager()"
            appearance="standard"
          >
            <mat-label>Anställd</mat-label>
            <mat-select
              class="select-font-size"
              (selectionChange)="onSelectUser($event)"
              [(value)]="selectedUser"
            >
              <mat-option
                *ngFor="let userObj of globalVariables.allUsers"
                [value]="userObj.user_mail"
              >
                {{ userObj.user_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- Payout panel start here -->
  <div style="margin-top: 10px">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description>
            Begär utbetalning
            <mat-icon>monetization_on</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Select all slider -->
        <div fxLayout="row" style="padding-bottom: 15px">
          <mat-radio-group
            [(ngModel)]="requestedType"
            (change)="onRequestOptionChange($event)"
          >
            <mat-radio-button color="primary" value="sal_out"
              >Lön</mat-radio-button
            >
            <mat-radio-button color="primary" value="pen"
              >Pension</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <div>
          <div fxLayout="row" style="padding-bottom: 5px">
            <mat-slide-toggle
              [disabled]="requestedType == ''"
              [(checked)]="requestSlideChecked"
              (change)="onRequestSlideChange($event)"
              color="primary"
              >Rubbet</mat-slide-toggle
            >
          </div>
          <div fxLayout="row" style="padding-bottom: 5px">
            <mat-slide-toggle
              [disabled]="requestedType == ''"
              [(checked)]="afterTaxSlideChecked"
              (change)="onTaxSlideChange($event)"
              color="primary"
              >Ange belopp före avgift/skatt</mat-slide-toggle
            >
          </div>
          <!-- Amount input -->
          <div
            fxLayout="column"
            fxLayout.lt-sm="column"
            fxLayoutGap="5px"
            fxLayoutGap.lt-sm="0px"
          >
            <div fxLayout="row" fxLayout.lt-sm="column">
              <div fxFlex.lt-sm="100" fxFlexOrder="-1">
                <mat-form-field
                  class="employeeDropDown"
                  appearance="standard"
                  style="margin-right: 10px"
                >
                  <mat-label *ngIf="afterTaxSlideChecked">Utbetalas</mat-label>
                  <mat-label *ngIf="!afterTaxSlideChecked"
                    >Från saldo</mat-label
                  >
                  <input
                    class="amount-field"
                    required
                    matInput
                    inputmode="numeric"
                    pattern="[0-9]"
                    type="number"
                    [(value)]="requestedAmount"
                    [disabled]="requestedAmountDisabled || requestedType == ''"
                    (change)="onRequestedAmountChange($event)"
                    autocomplete="off"
                  />
                  <span matSuffix>&nbsp;Kr</span>
                </mat-form-field>
              </div>
              <!-- Comment input -->
              <div
                stylefxFlex.lt-sm="100"
                fxFlexOrder="1"
                fxFlexOrder.lt-sm="2"
              >
                <mat-form-field class="comment-input" appearance="standard">
                  <mat-label>Frivilligt meddelande</mat-label>
                  <input
                    [disabled]="requestedType == ''"
                    class="comment-field"
                    matInput
                    type="text"
                    [(ngModel)]="requestComment"
                    autocomplete="off"
                  />
                  <button
                    mat-button
                    *ngIf="requestComment"
                    matSuffix
                    mat-icon-button
                    (click)="requestComment = ''"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
            <!-- Tax and payout display -->
            <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.lt-sm="1">
              <div>
                <div class="expanded-detail-group">
                  <mat-label
                    class="expanded-detail-title"
                    *ngIf="requestedType != 'pen'"
                    >Arbetsgivaravgift</mat-label
                  >

                  <mat-label
                    class="expanded-detail-title"
                    *ngIf="requestedType == 'pen'"
                    >Särskild löneskatt
                  </mat-label>
                  {{
                    -requestedAmountTax
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>

                <div
                  class="expanded-detail-group"
                  *ngIf="requestedType != 'pen'"
                >
                  <mat-label class="expanded-detail-title"
                    >Semestertillägg</mat-label
                  >
                  {{
                    -requestedAmountVacationPay
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>

                <div class="expanded-detail-group element-amount">
                  <mat-label
                    class="expanded-detail-title"
                    *ngIf="!afterTaxSlideChecked"
                    >Utbetalas</mat-label
                  >
                  <mat-label
                    class="expanded-detail-title"
                    *ngIf="afterTaxSlideChecked"
                    >Från saldo</mat-label
                  >

                  {{
                    requestedAmountPostTax
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <!-- Request button -->
              <div style="padding-left: 40px">
                <button
                  [disabled]="requestedType == ''"
                  mat-flat-button
                  color="primary"
                  (click)="onRequestClick()"
                >
                  Skicka
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- Total balance and sum of selection -->
  <div class="balanceRow">
    <div class="balanceTitle">Delsumma (filtrerad data):</div>

    <div
      class="balanceNumber"
      [ngStyle]="{ color: sumSelection < 0 ? '#de7d77' : 'white' }"
    >
      {{ sumSelection | number : "0.0-0" : "se-SE" }} Kr
    </div>
    <div class="balanceTitle">Aktuellt saldo:</div>

    <div
      class="balanceNumber"
      [ngStyle]="{ color: balance < 0 ? '#de7d77' : 'white' }"
    >
      {{ balance | number : "0.0-0" : "se-SE" }} Kr
    </div>
  </div>

  <!-- Table -->
  <table
    mat-table
    [dataSource]="dataSource"
    [multiTemplateDataRows]="true"
    matSort
    matSortActive="transaction_date"
    matSortDirection="desc"
    class="mat-elevation-z8"
  >
    <!-- Date Column -->
    <ng-container matColumnDef="transaction_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Datum
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.transaction_date | dateTimeFormatFilter : "YYYY-MM-DD" }}
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="transaction_type">
      <th mat-header-cell *matHeaderCellDef>Typ</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.transaction_type == 'car'"
          >directions_car</mat-icon
        >
        <mat-icon *ngIf="element.transaction_type == 'sal_in_ext'"
          >forward</mat-icon
        >

        <mat-icon
          *ngIf="element.transaction_type == 'sal_in_int'"
          svgIcon="octopi"
        ></mat-icon>
        <mat-icon *ngIf="element.transaction_type == 'pen'">person</mat-icon>
        <mat-icon *ngIf="element.transaction_type == 'sal_out'"
          >monetization_on</mat-icon
        >
        <mat-icon *ngIf="element.transaction_type == 'pur'"
          >shopping_basket</mat-icon
        >
      </td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Meddelande</th>
      <td mat-cell *matCellDef="let element">
        {{ element.comment }}
        <div
          class="dotted-row"
          type="inline"
          *ngIf="
            !globalVariables.isLoggedinUserManager() &&
            (element.transaction_type == 'sal_in_ext' ||
              element.transaction_type == 'sal_out' ||
              element.transaction_type == 'pen')
          "
        >
          ...
        </div>
        <div
          class="dotted-row-admin"
          type="inline"
          *ngIf="globalVariables.isLoggedinUserManager()"
        >
          ...
        </div>
      </td>
    </ng-container>

    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Belopp</th>
      <td
        mat-cell
        [ngStyle]="{ color: element.amount < 0 ? '#de7d77' : 'white' }"
        *matCellDef="let element"
      >
        {{ element.amount | number : "0.0-0" : "se-SE" }}
      </td>
    </ng-container>

    <!-- Detail expand -->
    <ng-container matColumnDef="expandedDetail">
      <td
        style="padding-left: 10px; padding-right: 10px"
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
        [@expandableRow]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <div *ngIf="element === expandedElement">
          <div fxFlex>
            <!-- Detail invoiced -->
            <div *ngIf="element.transaction_type == 'sal_in_ext'">
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Fakturerat</div>
                <div>
                  {{
                    element.invoiced
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Brytgräns</div>
                <div>
                  {{
                    element.breaking_limit
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Överskott</div>
                <div>
                  {{
                    element.surplus
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>

              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Ersättningsfaktor</div>
                <div>{{ element.factor }} %</div>
              </div>
              <div class="expanded-detail-group element-amount">
                <div class="expanded-detail-title">Ersättning</div>
                <div>
                  {{
                    element.amount
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
            </div>
            <!-- Detail payout -->
            <div *ngIf="element.transaction_type == 'sal_out'">
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Uttag</div>
                {{
                  changeSign(element.amount)
                    | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                }}
              </div>
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Arbetsgivaravgift</div>

                <div>
                  {{
                    changeSign(element.payout_tax)
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Semestertillägg</div>

                <div>
                  {{
                    changeSign(element.vacation_pay)
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <div class="expanded-detail-group element-amount">
                <div class="expanded-detail-title">Utbetalning</div>
                <div>
                  {{
                    changeSign(element.payout)
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
            </div>
            <!-- Detail pension-->
            <div *ngIf="element.transaction_type == 'pen'">
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Uttag</div>
                <div>
                  {{
                    changeSign(element.amount)
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Särskild löneskatt</div>
                <div>
                  {{
                    changeSign(element.payout_tax)
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <div class="expanded-detail-group element-amount">
                <div class="expanded-detail-title">Utbetalning</div>
                <div>
                  {{
                    changeSign(element.payout)
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
            </div>
            <!-- Detail internal time -->
            <div *ngIf="element.transaction_type == 'sal_in_int'">
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Antal timmar</div>
                <div>{{ element.hours }}</div>
              </div>
              <div class="expanded-detail-group">
                <div class="expanded-detail-title">Timersättning</div>
                <div>
                  {{
                    element.hourly_rate
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
              <div class="expanded-detail-group element-amount">
                <div class="expanded-detail-title">Summa</div>
                <div>
                  {{
                    element.amount
                      | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                  }}
                </div>
              </div>
            </div>

            <!-- Internal comment for admins-->
            <div
              *ngIf="globalVariables.isLoggedinUserManager()"
              class="element-internal_comment"
            >
              <span class="expanded-detail-title">Intern kommentar:&nbsp;</span>
              <span>{{ element.internal_comment }}</span>
            </div>
          </div>
          <div
            style="display: inline"
            *ngIf="globalVariables.isLoggedinUserAdmin()"
          >
            <div
              fxFlex
              fxLayout="column"
              fxLayoutAlign="start center"
              style="padding-bottom: 5px"
              fxLayoutGap="5px"
            >
              <mat-slide-toggle
                color="warn"
                [(ngModel)]="enableDelete"
              ></mat-slide-toggle>
              <button
                [disabled]="!enableDelete"
                mat-flat-button
                color="warn"
                (click)="onClickDeleteTransaction(element)"
              >
                Radera
              </button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="
        expandedElement =
          expandedElement === element
            ? null
            : globalVariables.isLoggedinUserManager() ||
              element.transaction_type == 'sal_in_ext' ||
              element.transaction_type == 'sal_out' ||
              element.transaction_type == 'pen'
            ? element
            : null
      "
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>
  </table>
</div>

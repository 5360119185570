import { Component, OnInit, ViewChild } from '@angular/core';
import { Transaction } from '../../core/classes/Transaction';
import { GlobalVariables } from '../../core/global/globalvariables';
import { expandableRowAnimation } from './expandable-row.animation';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RestService } from 'src/app/core/services/rest.service';
import { MsalService } from '@azure/msal-angular';
import { GlobalFunctions } from 'src/app/core/global/globalFunctions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [expandableRowAnimation],
})
export class HomeComponent implements OnInit {
  dataSource: any;
  constructor(
    private restService: RestService,
    public globalVariables: GlobalVariables,
    public globalFunctions: GlobalFunctions,
    private snackBar: MatSnackBar,
    private msalService: MsalService
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'transaction_date',
    'transaction_type',
    'comment',
    'amount',
  ];

  expandedElement: Transaction;

  transactions: Transaction[] = [];
  balance: number = 0;
  sumSelection = 0;
  selectedUser = this.getLoggedinUserMailFromAzure();

  filterType: TransactionType = {
    value: '',
    icon_type: 'ico',
    icon: 'remove',
    viewValue: 'Alla',
  };
  selectedType = '';

  loggedInUserMailAzure: string;
  toDate = new Date();
  fromDate = new Date();
  filterIcon: string = 'forward';
  maxDate = new Date();

  requestedType = '';
  requestedAmount = 0;
  requestedAmountDisabled = false;
  requestComment: string;
  requestSlideChecked = false;
  requestedAmountTax = 0;
  requestedAmountVacationPay = 0;
  requestedAmountPostTax = 0;
  afterTaxSlideChecked = true;

  enableDelete = false;

  async ngOnInit(): Promise<void> {
    this.fromDate.setMonth(new Date().getMonth() - 6);
    //this.toDate.setDate();

    await this.loadLoggedInUser();

    this.loadTransaction();
    this.loadBalance();
  }

  async loadLoggedInUser() {
    await this.restService
      .getUser(this.getLoggedinUserMailFromAzure())
      .toPromise()
      .then((result) => {
        this.globalVariables.loggedInUser = result[0];
        this.globalVariables.selectedUser = result[0];
      });
  }

  getMaxDate() {
    if (this.globalVariables.isLoggedinUserManager()) {
      return '';
    }
    return this.maxDate;
  }

  getLoggedinUserMailFromAzure() {
    if (!this.loggedInUserMailAzure) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.loggedInUserMailAzure = accounts[0].username;
    }
    return this.loggedInUserMailAzure;
  }

  onSelectUser(event: any) {
    this.globalVariables.selectedUser = this.globalVariables.allUsers.find(
      (i) => i.user_mail === event.value
    );
    this.loadTransaction();
    this.loadBalance();
  }

  onSelectType(event: any) {
    this.filterType = this.globalVariables.transactionTypes.find(
      (i) => i.value === event.value
    );
    this.loadTransaction();
  }

  onChangeFromDate(event: any) {
    this.fromDate = event.target.value;
    this.loadTransaction();
  }

  onChangeToDate(event: any) {
    this.toDate = event.target.value;
    this.loadTransaction();
  }

  onTaxSlideChange($event: MatSlideToggleChange) {
    this.afterTaxSlideChecked = $event.checked;
    if (this.requestedAmountPostTax) {
      this.setReqAmounts(Math.round(this.requestedAmountPostTax));
    }
  }

  //Salary or Pension
  onRequestOptionChange(event: any) {
    if (this.requestSlideChecked) {
      // Rubbet valt
      this.setReqAmounts(
        Math.round(
          this.requestedAmount *
            (1 / (1 + this.globalFunctions.getEmployerFee())) *
            (1 / (1 + this.globalFunctions.getVacationPay()))
        )
      );
    } else {
      //Eget belopp
      this.setReqAmounts(Math.round(this.requestedAmount));
    }
  }

  //Rubbet or own value
  onRequestSlideChange($event: MatSlideToggleChange) {
    if ($event.checked) {
      if (this.afterTaxSlideChecked) {
        this.setReqAmounts(
          Math.round(
            this.balance *
              (1 / (1 + this.globalFunctions.getEmployerFee())) *
              (1 / (1 + this.globalFunctions.getVacationPay()))
          )
        );
      } else {
        this.setReqAmounts(Math.round(this.balance));
      }
    }
    this.requestedAmountDisabled = $event.checked;
    this.requestSlideChecked = $event.checked;
  }

  onRequestedAmountChange($event) {
    this.setReqAmounts(parseFloat($event.target.value));
  }

  setReqAmounts(value: number) {
    this.requestedAmount = value;
    if (this.afterTaxSlideChecked) {
      let payout = this.globalFunctions.getPayout(
        this.requestedType,
        this.requestedAmount,
        false
      );
      this.requestedAmountVacationPay = -payout.vacationPay;
      this.requestedAmountTax = -payout.tax;
      this.requestedAmountPostTax = payout.amountIncludingFees;
    } else {
      let payout = this.globalFunctions.getPayout(
        this.requestedType,
        this.requestedAmount,
        true
      );
      this.requestedAmountVacationPay = payout.vacationPay;
      this.requestedAmountTax = payout.tax;
      this.requestedAmountPostTax = payout.amountExcludingFees;
    }
  }

  onRequestClick() {
    if (this.requestedAmount) {
      if (
        // this.requestedAmount.length == 0 ||
        Number(this.requestedAmount) < 1
      ) {
        this.snackBar.open('Du måste fylla i ett belopp', 'OK', {
          duration: 2000,
          panelClass: ['mat-toolbar', 'mat-warn'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        let amount = this.requestedAmount;
        if (this.afterTaxSlideChecked) {
          amount = this.globalFunctions.increaseAmountWithTax(
            this.requestedType,
            this.requestedAmount
          );
        }
        let comment = this.requestComment;
        if (!comment) {
          comment = '--';
        }
        this.restService
          .createPayoutRequest(
            this.globalVariables.selectedUser.user_mail,
            this.globalVariables.selectedUser.user_name,
            this.requestedType,
            amount,
            comment
          )
          .toPromise()
          .then(() => {
            this.requestComment = '';
            this.requestedAmount = 0;
            this.requestSlideChecked = false;
            this.requestedAmountDisabled = false;
            this.requestedAmountTax = 0;
            this.requestedAmountPostTax = 0;
            this.requestedAmountVacationPay = 0;
            this.requestedType = '';

            this.snackBar.open('Begäran skickad', 'OK', {
              duration: 2000,
              panelClass: ['mat-toolbar', 'mat-primary'],
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          });
      }
    } else {
      this.snackBar.open('Du måste fylla i ett belopp', 'OK', {
        duration: 2000,
        panelClass: ['mat-toolbar', 'mat-warn'],
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  onClickDeleteTransaction(event: any) {
    let json_st = {
      user_mail: event.user_mail,
      key: event.key,
      status: 'deleted',
    };

    this.restService
      .updateTransaction(json_st)
      .toPromise()
      .then(() => {
        this.dataSource.data = this.dataSource.data.filter((i) => i !== event);
        this.snackBar.open('Transaktion raderad', 'OK', {
          duration: 2000,
          panelClass: ['mat-toolbar', 'mat-primary'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.enableDelete = false;
      });
  }

  loadTransaction() {
    this.restService
      .getTransactions(
        this.globalVariables.selectedUser.user_mail,
        this.formatDate(this.fromDate),
        this.formatDate(this.toDate),
        this.filterType.value,
        'true'
      )
      .subscribe(
        (response) => {
          this.transactions = response;
        },
        (error) => {},
        () => {
          this.dataSource = new MatTableDataSource(this.transactions);
          this.dataSource.sort = this.sort;
          // Calculate selection balance
          this.sumSelection = 0;
          this.dataSource.data.forEach((element) => {
            if (element.amount) {
              this.sumSelection =
                this.sumSelection + parseFloat(element.amount);
            }
          });
        }
      );
  }

  loadBalance() {
    let allTransactions: Transaction[] = [];
    this.restService
      .getTransactions(
        this.globalVariables.selectedUser.user_mail,
        '',
        '',
        '',
        'true'
      )
      .subscribe(
        (response) => {
          allTransactions = response;
        },
        (error) => {},
        () => {
          this.balance = 0;
          if (allTransactions) {
            allTransactions.forEach((element) => {
              if (element.amount) {
                let amountNumber = parseFloat(element.amount);
                this.balance = this.balance + amountNumber;
              }
            });
          }
        }
      );
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  changeSign(value) {
    if (value) {
      let number = Number(value);
      return 0 - number;
    }
  }

  compareFn(f1: TransactionType, f2: TransactionType): boolean {
    return f1 && f2 ? f1.value === f2.value : f1 === f2;
  }
}

export interface TransactionType {
  value: string;
  viewValue: string;
  icon_type: string;
  icon: any;
}

import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/classes/User';
import { GlobalVariables } from '../../core/global/globalvariables';
import { RestService } from 'src/app/core/services/rest.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss'],
})
export class UserAdminComponent implements OnInit {
  constructor(
    public globalVariables: GlobalVariables,
    private restService: RestService
  ) {}

  selectedUser: string;

  email = new FormControl('', [Validators.required, Validators.email]);

  biodataForm: FormGroup = new FormGroup({
    user_mail: new FormControl('', Validators.required),
    user_name: new FormControl('', Validators.required),
  });

  get user_name() {
    return this.biodataForm.get('user_name');
  }

  userData = this.globalVariables.emptyUser;
  loaded = false;

  ngOnInit(): void {}

  onChangeUser() {
    this.loaded = false;
    this.loadUser();
  }

  loadUser() {
    this.restService.getUser(this.selectedUser).subscribe(
      (response) => {
        this.userData = response[0];
      },
      (error) => {},
      () => {
        this.loaded = true;
      }
    );
  }

  onUpdateUser() {
    console.log(this.biodataForm.valid);
    console.log(this.userData);
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Du måste ange ett värde';
    }

    return this.email.hasError('email') ? 'Inte en korrekt e-postadress' : '';
  }
}

import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { toUnicode } from 'punycode';
import { GlobalVariables } from 'src/app/core/global/globalvariables';
import { RestService } from 'src/app/core/services/rest.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  productionEnv: boolean;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private msalService: MsalService,
    private domSanitizer: DomSanitizer,
    private restService: RestService,
    public globalVariables: GlobalVariables
  ) {
    this.matIconRegistry.addSvgIcon(
      'octopi',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/octopi_ico.svg'
      )
    );
  }

  roles;

  ngOnInit(): void {
    this.roles =
      this.msalService.instance.getAllAccounts()[0].idTokenClaims['roles'][0];
    this.globalVariables.loggedInUserRole = this.roles;
    this.loadAllUsers();
    this.loadInternalProjects();
    this.productionEnv = environment.production;
  }

  getFirstName() {
    return this.msalService.instance
      .getAllAccounts()[0]
      .name.substring(
        0,
        this.msalService.instance.getAllAccounts()[0].name.indexOf(' ')
      );
  }

  private loadInternalProjects() {
    this.restService.getInternalProjects().subscribe((response) => {
      this.globalVariables.internalProjects = response.sort();
    });
  }

  private loadAllUsers() {
    this.restService.getUsers().subscribe((response) => {
      this.globalVariables.allUsers = response.sort();
    });
  }

  onClickLogout() {
    this.msalService.logout();
  }
}

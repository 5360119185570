import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalVariables } from '../../core/global/globalvariables';
import { TransactionType } from '../home/home.component';
import { Transaction } from '../../core/classes/Transaction';
import { User } from '../../core/classes/User';
import { TimeEntry } from 'src/app/core/classes/TimeEntry';
import { RestService } from 'src/app/core/services/rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Billable } from 'src/app/core/classes/Billable';
import { SelectionModel } from '@angular/cdk/collections';
import { WintEmployee } from 'src/app/core/classes/WintEmployee';

const monthNames = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];

@Component({
  selector: 'app-time-admin',
  templateUrl: './time-admin.component.html',
  styleUrls: ['./time-admin.component.scss'],
})
export class TimeAdminComponent implements OnInit {
  constructor(
    private restService: RestService,
    public globalVariables: GlobalVariables,
    private snackBar: MatSnackBar
  ) {}

  dataSource: any;
  startDate;
  endDate;
  comment;
  transaction: Transaction = {
    user_mail: '',
    status: '',
    key: '',
    breaking_limit: '',
    comment: '',
    factor: '',
    invoiced: '',
    surplus: '',
    transaction_date: '',
    amount: '',
    transaction_type: '',
    payout_tax: '',
    vacation_pay: '',
    payout: '',
    internal_comment: '',
    harvest_entry_id: '',
    hours: '',
    hourly_rate: '',
  };

  selectedTransactionType: TransactionType = {
    value: '',
    icon_type: '',
    icon: '',
    viewValue: '',
  };
  selectedUser: User = {
    user_mail: '',
    user_name: '',
    key: '',
    overtime: '',
    variable_salary: '',
    breaking_limit: '',
    factor: '',
    balance: '',
    harvest_id: '',
    equipment_reset_date: '',
    equipment_budget: '',
    wellness_budget: '',
    education_budget: '',
    car_leasing_fee: '',
    car_leasing_contribution: '0',
    special_deal_harvest_id: '',
    special_deal_rate: '',
  };
  timeEntries: TimeEntry[] = [];
  billableEntries: Billable[] = [];
  displayedColumns: string[] = [
    'date',
    'user_name',
    'project_name',
    'task_name',
    'hours',
    'hourly_rate_1',
    'total_amount_1',
    'hourly_rate_2',
    'total_amount_2',
  ];

  selection = new SelectionModel<TimeEntry>(true, []);

  wintEmployees: WintEmployee[] = [];

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.loadWintEmployees();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAnySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  onClickInternal() {
    this.selection.clear();
    this.loadInternalTime(
      this.startDate.format('YYYYMMDD'),
      this.endDate.format('YYYYMMDD'),
      false
    );
  }

  onClickAbsence() {
    this.selection.clear();

    this.loadInternalTime(
      this.startDate.format('YYYYMMDD'),
      this.endDate.format('YYYYMMDD'),
      true
    );
  }

  onClickBillable() {
    this.selection.clear();
    this.loadBillable(
      this.startDate.format('YYYYMMDD'),
      this.endDate.format('YYYYMMDD')
    );
  }

  onSelectUser(event: any) {
    this.selectedUser = event.value;
    this.transaction.breaking_limit = event.value.breaking_limit;
    this.transaction.factor = event.value.factor;
    this.calculateFields();
  }

  onSelectType(event: any) {
    this.selectedTransactionType = event.value;
    this.calculateFields();
  }

  public calculateFields() {
    switch (this.selectedTransactionType.value) {
      case GlobalVariables.TYPE_SALARY_OUT:
        if (this.transaction.amount) {
          this.transaction.payout_tax =
            '' +
            -Math.round(Number(this.transaction.amount) * (1 - 1 / 1.3142));

          this.transaction.vacation_pay =
            '' +
            -Math.round(
              (Number(this.transaction.amount) +
                Number(this.transaction.payout_tax)) *
                (1 - 1 / 1.12)
            );
          this.transaction.payout =
            '' +
            +Math.round(
              Number(this.transaction.amount) +
                Number(this.transaction.payout_tax) +
                Number(this.transaction.vacation_pay)
            );
        }
        this.transaction.comment = 'Löneuttag';
        break;

      case GlobalVariables.TYPE_PENSION_OUT:
        if (this.transaction.amount) {
          this.transaction.payout_tax =
            '' +
            -Math.round(Number(this.transaction.amount) * (1 - 1 / 1.2426));
          this.transaction.payout =
            '' +
            +Math.round(
              Number(this.transaction.amount) +
                Number(this.transaction.payout_tax)
            );
        }
        this.transaction.comment = 'Pensionsuttag';
        break;

      case GlobalVariables.TYPE_SALARY_IN_EXT:
        if (
          this.transaction.invoiced &&
          this.transaction.breaking_limit &&
          this.transaction.factor
        ) {
          let surplus =
            Number(this.transaction.invoiced) -
            Number(this.transaction.breaking_limit);

          // Check if surplus if greater than zero
          if (surplus > 0) {
            // If yes, set surplus and calculate payout amount
            this.transaction.surplus = '' + surplus;
            this.transaction.amount =
              '' + Math.round(surplus * Number(this.transaction.factor) * 0.01);
          } else {
            // If no, set surplus and payout amount to zero
            this.transaction.surplus = '' + 0;
            this.transaction.amount = '' + 0;
          }
        }
        if (this.transaction.transaction_date) {
          var selectedDate = new Date(this.transaction.transaction_date);
          this.transaction.comment = monthNames[selectedDate.getMonth()];
        }
        break;
    }
  }

  onClickCreateTransaction(button: string, event: any) {
    let amount = '';
    let hourly_rate = '';
    let user_mail = this.globalVariables.allUsers.find(
      (x) => x.harvest_id == event.user_id
    ).user_mail;

    switch (button) {
      case 'total_amount_1':
        amount = event.total_amount_1;
        hourly_rate = event.hourly_rate_1;
        event.total_amount_1_checked = true;
        break;
      case 'total_amount_2':
        amount = event.total_amount_2;
        hourly_rate = event.hourly_rate_2;
        event.total_amount_2_checked = true;
        break;
    }

    let newTransaction = new Transaction(
      user_mail,
      'valid',
      '',
      '',
      event.task_name,
      '',
      '',
      '',
      this.formatDate(event.date),
      amount,
      'sal_in_int',
      '',
      '',
      '',
      'Timmar: ' + event.hours,
      event.entry_id,
      event.hours,
      hourly_rate
    );

    this.restService
      .createTransaction(newTransaction)
      .toPromise()
      .then(() => {
        event.isProcessed = true;
        this.snackBar.open('Transaktionen skapad', 'OK', {
          duration: 2000,
          panelClass: ['mat-toolbar', 'mat-primary'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  onClickCreateBillable(event: any) {
    let user_obj = this.globalVariables.allUsers.find(
      (x) => x.harvest_id == event.user_id
    );

    let surplus =
      Number(event.billable_amount) - Number(user_obj.breaking_limit);
    let amount = 0;

    // Check if surplus if greater than zero
    if (surplus > 0) {
      // If yes, set surplus and calculate payout amount

      amount = Math.round(surplus * Number(user_obj.factor) * 0.01);
    } else {
      // If no, set surplus  to zero
      surplus = 0;
    }

    let newTransaction = this.getTransactionObject();

    newTransaction.user_mail = user_obj.user_mail;
    newTransaction.status = 'valid';
    newTransaction.invoiced = '' + Math.round(event.billable_amount);
    newTransaction.surplus = '' + Math.round(surplus);
    newTransaction.factor = user_obj.factor;
    newTransaction.breaking_limit = user_obj.breaking_limit;
    newTransaction.amount = '' + amount;
    newTransaction.transaction_date = this.formatDate(this.endDate);
    newTransaction.transaction_type = GlobalVariables.TYPE_SALARY_IN_EXT;
    let d = new Date(this.endDate);
    newTransaction.comment = monthNames[d.getMonth()];

    this.restService
      .createTransaction(newTransaction)
      .toPromise()
      .then(() => {
        event.isProcessed = true;
        this.snackBar.open('Transaktionen skapad', 'OK', {
          duration: 2000,
          panelClass: ['mat-toolbar', 'mat-primary'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  createTransaction() {
    let newTransaction: Transaction = {
      user_mail: '',
      status: '',
      key: '',
      breaking_limit: '',
      comment: '',
      factor: '',
      invoiced: '',
      surplus: '',
      transaction_date: '',
      amount: '',
      transaction_type: '',
      payout_tax: '',
      vacation_pay: '',
      payout: '',
      internal_comment: '',
      harvest_entry_id: '',
      hours: '',
      hourly_rate: '',
    };
    newTransaction.transaction_date = this.formatDate(
      this.transaction.transaction_date
    );
    newTransaction.transaction_type = this.selectedTransactionType.value;

    newTransaction.comment = this.transaction.comment;
    newTransaction.internal_comment = this.transaction.internal_comment;
    newTransaction.user_mail = this.selectedUser.user_mail;
    newTransaction.status = 'valid';

    if (
      this.selectedTransactionType.value == GlobalVariables.TYPE_PENSION_OUT ||
      this.selectedTransactionType.value == GlobalVariables.TYPE_SALARY_OUT
    ) {
      newTransaction.amount = '' + -this.transaction.amount;
      newTransaction.payout_tax = '' + -this.transaction.payout_tax;
      newTransaction.payout = '' + -this.transaction.payout;
      newTransaction.vacation_pay = '' + -this.transaction.vacation_pay;
    } else if (
      this.selectedTransactionType.value == GlobalVariables.TYPE_SALARY_IN_EXT
    ) {
      newTransaction.invoiced = this.transaction.invoiced;
      newTransaction.breaking_limit = this.transaction.breaking_limit;
      newTransaction.surplus = this.transaction.surplus;
      newTransaction.factor = this.transaction.factor;
      newTransaction.amount = this.transaction.amount;
    } else if (
      this.selectedTransactionType.value == GlobalVariables.TYPE_SALARY_IN_INT
    ) {
      newTransaction.amount = this.transaction.amount;
    } else {
      newTransaction.amount = '' + -this.transaction.amount;
    }

    this.restService
      .createTransaction(newTransaction)
      .toPromise()
      .then(() => {
        this.snackBar.open('Transaktionen skapad', 'OK', {
          duration: 2000,
          panelClass: ['mat-toolbar', 'mat-primary'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  private loadInternalTime(fromDate, toDate, absence: boolean) {
    this.restService
      .getInternalTime(fromDate, toDate, !absence, !absence, absence)
      .subscribe(
        (response) => {
          if (absence) {
            this.displayedColumns = [
              'select',
              'date',
              'user_name',
              'project_name',
              'task_name',
              'notes',
              'hours',
            ];
          } else {
            this.displayedColumns = [
              'date',
              'user_name',
              'project_name',
              'task_name',
              'hours',
              'hourly_rate_1',
              'total_amount_1',
              'hourly_rate_2',
              'total_amount_2',
            ];
          }
          this.timeEntries = response;
        },
        (error) => console.log(error),
        () => {
          this.timeEntries.forEach((element) => {
            let internalProject = this.globalVariables.internalProjects.find(
              (x) => x.project_id == element.task_id
            );
            if (!internalProject) {
              console.log(
                'cannot find project for task id:' +
                  element.task_id +
                  ' ' +
                  element.task_name
              );
              return;
            }
            element.hourly_rate_1 = internalProject.hourly_rate_1;
            element.total_amount_1 =
              '' +
              Number(internalProject.hourly_rate_1) * Number(element.hours);
            element.hourly_rate_2 = internalProject.hourly_rate_2;
            element.total_amount_2 =
              '' +
              Number(internalProject.hourly_rate_2) * Number(element.hours);
          });
          this.dataSource = new MatTableDataSource(this.timeEntries);
          this.dataSource.sort = this.sort;
        }
      );
  }

  private loadBillable(fromDate, toDate) {
    this.restService.getBillable(fromDate, toDate).subscribe(
      (response) => {
        this.displayedColumns = [
          'user_name',
          'billable_hours',
          'billable_amount',
        ];
        this.billableEntries = response;
      },
      (error) => console.log(error),
      () => {
        this.dataSource = new MatTableDataSource(this.billableEntries);
        this.dataSource.sort = this.sort;
      }
    );
  }

  private loadWintEmployees() {
    this.restService.getWintEmployees().subscribe((response) => {
      this.wintEmployees = response;
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  formatYearAndMonth(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    let yearMonth = [year, month].join('');
    return Number(yearMonth);
  }

  getTransactionObject() {
    let newTransaction: Transaction = {
      user_mail: '',
      status: '',
      key: '',
      breaking_limit: '',
      comment: '',
      factor: '',
      invoiced: '',
      surplus: '',
      transaction_date: '',
      amount: '',
      transaction_type: '',
      payout_tax: '',
      vacation_pay: '',
      payout: '',
      internal_comment: '',
      harvest_entry_id: '',
      hours: '',
      hourly_rate: '',
    };

    return newTransaction;
  }

  wintUsers = [];
  // sort selected rows before looping
  sendToWint() {
    this.selection.sort((a, b) =>
      a.user_name > b.user_name
        ? 1
        : a.user_name === b.user_name
        ? a.task_name > b.task_name
          ? 1
          : a.task_name === b.task_name
          ? a.date > b.date
            ? 1
            : -1
          : -1
        : -1
    );

    let currentUser = '';
    let currentType = '';
    let currentDate: Date;
    let lastDate: Date;

    for (let element of this.selection.selected) {
      currentDate = new Date(element.date);
      if (
        currentUser != element.user_name ||
        currentType != element.task_id ||
        currentDate.getDate() != lastDate.getDate() + 1
      ) {
        // New user and/or new type
        if (currentType != '') {
          // Not first run -> print
          console.log(JSON.stringify(currentDeviation));
          this.createSalaryDeviation(currentDeviation);
        }

        currentType = element.task_id;
        currentUser = element.user_name;

        let wintType = harvestWintMapping.filter(
          (harvestWint) => harvestWint.harvestId == currentType
        )[0].wintType;

        let wintEmployee = this.wintEmployees.find(
          (i) => i.Name === element.user_name
        );

        var currentDeviation = new deviation();
        currentDeviation.personId = wintEmployee.Id;
        currentDeviation.deviationType = wintType;
        currentDeviation.fromDate = element.date;
        currentDeviation.toDate = element.date;
        lastDate = new Date(element.date);
        currentDeviation.yearAndMonth = this.formatYearAndMonth(this.startDate);
        if (element.task_id == HARVEST_ID_PARENTAL_LEAVE) {
          currentDeviation.childName = element.notes;
        }

        let currentDayHours = new dayHours();
        currentDayHours.day = element.date;
        currentDayHours.hours = Number(element.hours);
        currentDeviation.dayHours = [currentDayHours];
      } else {
        // Same type
        let currentDayHours = new dayHours();
        currentDayHours.day = element.date;
        currentDayHours.hours = Number(element.hours);
        currentDeviation.dayHours.push(currentDayHours);

        currentDeviation.toDate = element.date;
        lastDate = new Date(element.date);
      }
    }
    console.log(JSON.stringify(currentDeviation));
    this.createSalaryDeviation(currentDeviation);
  }

  createSalaryDeviation(json_data) {
    let json = JSON.stringify(json_data);

    this.restService
      .createSalaryDeviation(json)
      .toPromise()
      .then((hello) => {})
      .catch((err) => {
        console.log(err);
      });
  }
}
class deviation {
  personId: string;
  yearAndMonth: number;
  fromDate: string;
  toDate: string;
  dayHours: [dayHours];
  deviationType: number;
  childName: string;
}

class dayHours {
  day: string;
  hours: number;
}

const WINT_TYPE_VACATION = 1;
const WINT_TYPE_VAB = 2;
const WINT_TYPE_SICK = 3;
const WINT_TYPE_PARENTAL_LEAVE = 4;

const HARVEST_ID_VACATION = '7820445';
const HARVEST_ID_VAB = '7820446';
const HARVEST_ID_SICK = '7820448';
const HARVEST_ID_PARENTAL_LEAVE = '7820447';

const harvestWintMapping = [
  { harvestId: HARVEST_ID_VACATION, wintType: WINT_TYPE_VACATION },
  { harvestId: HARVEST_ID_VAB, wintType: WINT_TYPE_VAB },
  { harvestId: HARVEST_ID_SICK, wintType: WINT_TYPE_SICK },
  { harvestId: HARVEST_ID_PARENTAL_LEAVE, wintType: WINT_TYPE_PARENTAL_LEAVE },
];

export class Transaction {
  user_mail: string;
  status: string;
  key: string;
  breaking_limit: string;
  comment: string;
  factor: string;
  invoiced: string;
  surplus: string;
  transaction_date: string;
  amount: string;
  transaction_type: string;
  payout_tax: string;
  vacation_pay: string;
  payout: string;
  internal_comment: string;
  harvest_entry_id: string;
  hours: string;
  hourly_rate: string;

  constructor(
    user_mail: string,
    status: string,
    key: string,
    breaking_limit: string,
    comment: string,
    factor: string,
    invoiced: string,
    surplus: string,
    transaction_date: string,
    amount: string,
    transaction_type: string,
    payout_tax: string,
    vacation_pay: string,
    payout: string,
    internal_comment: string,
    harvest_entry_id: string,
    hours: string,
    hourly_rate: string
  ) {
    this.user_mail = user_mail;
    this.status = status;
    this.key = key;
    this.breaking_limit = breaking_limit;
    this.comment = comment;
    this.factor = factor;
    this.invoiced = invoiced;
    this.surplus = surplus;
    this.transaction_date = transaction_date;
    this.amount = amount;
    this.transaction_type = transaction_type;
    this.payout_tax = payout_tax;
    this.vacation_pay = vacation_pay;
    this.payout = payout;
    this.internal_comment = internal_comment;
    this.harvest_entry_id = harvest_entry_id;
    this.hours = hours;
    this.hourly_rate = hourly_rate;
  }
}

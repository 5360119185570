<div>
  <mat-form-field class="form-field" appearance="fill">
    <mat-label>Anställd</mat-label>
    <mat-select [(value)]="selectedUser" (selectionChange)="onChangeUser()">
      <mat-option
        *ngFor="let userObj of globalVariables.allUsers"
        [value]="userObj.user_mail"
      >
        {{ userObj.user_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<form class="form" name="biodataForm" [formGroup]="biodataForm">
  <div
    fxLayout="column wrap"
    fxLayoutGap="1px"
    fxLayoutAlign="flex-start"
    style="width: 100%; background-color: #262626; padding-left: 10px"
  >
    <div><h2>Allmänt</h2></div>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>E-post</mat-label>

          <input
            matInput
            required
            readonly
            name="user_mail"
            [(ngModel)]="userData.user_mail"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>DB-nyckel</mat-label>
          <input
            matInput
            required
            readonly
            [(ngModel)]="userData.key"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Namn</mat-label>
          <input
            matInput
            required
            [(ngModel)]="userData.user_name"
            [ngModelOptions]="{ standalone: true }"
          />
          <mat-error *ngIf="userData.user_name == ''">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Harvest-ID</mat-label>
          <input
            matInput
            required
            [(ngModel)]="userData.harvest_id"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
    </div>
    <div><mat-divider></mat-divider></div>

    <div><h2>Lön</h2></div>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Månadslön</mat-label>
          <input matInput readonly />
        </mat-form-field>
      </div>
      <div
        fxLayout="row wrap"
        fxLayoutGap="10px"
        fxLayoutAlign="flex-start"
        style="border-top: solid 1px"
      >
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Brytgräns</mat-label>
            <input
              matInput
              required
              [(ngModel)]="userData.breaking_limit"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Faktor</mat-label>
            <input
              matInput
              required
              [(ngModel)]="userData.factor"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
      </div>

      <div
        fxLayout="row wrap"
        fxLayoutGap="10px"
        fxLayoutAlign="flex-start"
        style="border-top: solid 1px"
      >
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Harvest projekt-ID</mat-label>
            <input
              matInput
              [(ngModel)]="userData.special_deal_harvest_id"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Timpris</mat-label>
            <input
              matInput
              [(ngModel)]="userData.special_deal_rate"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div><mat-divider></mat-divider></div>

    <div><h2>Förmåner</h2></div>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start">
      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Friskvårdsbudget</mat-label>
          <input
            matInput
            required
            [(ngModel)]="userData.wellness_budget"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Utbildningsbudget</mat-label>
          <input
            matInput
            required
            [(ngModel)]="userData.education_budget"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
      <div
        fxLayout="row wrap"
        fxLayoutGap="10px"
        fxLayoutAlign="flex-start"
        style="border-top: solid 1px"
      >
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Utrustningsbudget</mat-label>
            <input
              matInput
              required
              [(ngModel)]="userData.equipment_budget"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Datum</mat-label>
            <input
              matInput
              required
              [(ngModel)]="userData.equipment_reset_date"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        fxLayout="row wrap"
        fxLayoutGap="10px"
        fxLayoutAlign="flex-start"
        style="border-top: solid 1px"
      >
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Leasingbilskostnad</mat-label>
            <input
              matInput
              [(ngModel)]="userData.car_leasing_fee"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field" appearance="fill">
            <mat-label>Leasingbilsbidrag</mat-label>
            <input
              matInput
              [(ngModel)]="userData.car_leasing_contribution"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div><mat-divider></mat-divider></div>
  </div>
</form>
<div>
  <span>
    <button mat-button mat-flat-button color="primary" (click)="onUpdateUser()">
      Spara
    </button>
  </span>
</div>

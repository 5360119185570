import { Component, Input, OnInit } from '@angular/core';
import { GlobalVariables } from 'src/app/core/global/globalvariables';
import { RestService } from 'src/app/core/services/rest.service';
import { GlobalFunctions } from 'src/app/core/global/globalFunctions';
import { Wellness } from 'src/app/core/classes/Wellness';
import { expandableRowAnimation } from '../../home/expandable-row.animation';
import { Education } from 'src/app/core/classes/Education';
import { Equipment } from 'src/app/core/classes/Equipment';
import { Vacation } from 'src/app/core/classes/Vacation';

@Component({
  selector: 'app-mini-card',
  templateUrl: './mini-card.component.html',
  styleUrls: ['./mini-card.component.scss'],
  animations: [expandableRowAnimation],
})
export class MiniCardComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;
  @Input() icon: string;
  @Input() icon_color: string;
  constructor(
    private restService: RestService,
    public globalVariables: GlobalVariables,
    public globalFunctions: GlobalFunctions
  ) {}

  currentYear = new Date().getFullYear();

  wellness: Wellness[] = [];
  wellnessBudget = 0;

  education: Education[] = [];
  educationBudget = 0;

  equipment: Equipment[] = [];
  equipmentBudget = 0;
  equipmentResetDate;

  vacation: Vacation[] = [];
  vacationTotal = 0;
  vacationPaid = '';
  vacationUnpaid = '';
  vacationSaved = '';
  vactionUpdatedUntil;

  hasCar = false;
  carCost = 0;
  carLeasingFee = 0;
  carContribution = 0;

  collapsed = true;

  displayedColumns: string[] = ['transaction_date', 'comment', 'amount'];
  dataSource: any;

  ngOnInit(): void {
    switch (this.type) {
      case 'vacation':
        this.loadVacation();
        break;
      case 'wellness':
        this.loadWellness();
        break;
      case 'education':
        this.loadEducation();
        break;
      case 'car':
        this.loadCar();
        break;
      case 'equipment':
        var resetDate = new Date(
          this.globalVariables.selectedUser.equipment_reset_date
        );
        resetDate.setFullYear(resetDate.getFullYear() + 3);
        this.equipmentResetDate = resetDate;
        this.loadEquipment();
        break;
    }
  }

  loadWellness() {
    var today = new Date();

    this.restService
      .getWellnessByYear(
        this.globalVariables.selectedUser.user_mail,
        today.getFullYear()
      )
      .subscribe(
        (response) => {
          this.wellness = response;
          this.dataSource = this.wellness;
        },
        (error) => {},
        () => {
          //Sort by date
          this.dataSource.sort((a, b) =>
            b.transaction_date.localeCompare(a.transaction_date)
          );

          this.wellnessBudget = Number(
            this.globalVariables.selectedUser.wellness_budget
          );
          // Calculate remaining balance
          this.wellness.forEach((element) => {
            if (element.amount) {
              this.wellnessBudget =
                this.wellnessBudget - parseFloat(element.amount);
            }
          });
        }
      );
  }

  loadEducation() {
    var today = new Date();

    this.restService
      .getEducationByYear(
        this.globalVariables.selectedUser.user_mail,
        today.getFullYear()
      )
      .subscribe(
        (response) => {
          this.education = response;
          this.dataSource = this.education;
        },
        (error) => {},
        () => {
          //Sort by date
          this.dataSource.sort((a, b) =>
            b.transaction_date.localeCompare(a.transaction_date)
          );

          this.educationBudget = Number(
            this.globalVariables.selectedUser.education_budget
          );
          // Calculate remaining balance
          this.education.forEach((element) => {
            if (element.amount) {
              this.educationBudget =
                this.educationBudget - parseFloat(element.amount);
            }
          });
        }
      );
  }

  loadEquipment() {
    var today = new Date();

    this.restService
      .getEquipment(
        this.globalVariables.selectedUser.user_mail,
        this.globalVariables.selectedUser.equipment_reset_date,
        this.globalFunctions.formatDate(today)
      )
      .subscribe(
        (response) => {
          this.equipment = response;
          this.dataSource = this.equipment;
        },
        (error) => {},
        () => {
          //Sort by date
          this.dataSource.sort((a, b) =>
            b.transaction_date.localeCompare(a.transaction_date)
          );

          this.equipmentBudget = Number(
            this.globalVariables.selectedUser.equipment_budget
          );
          // Calculate remaining balance
          this.equipment.forEach((element) => {
            if (element.amount) {
              this.equipmentBudget =
                this.equipmentBudget - parseFloat(element.amount);
            }
          });
        }
      );
  }

  loadVacation() {
    this.restService
      .getVacation(this.globalVariables.selectedUser.user_mail)
      .subscribe(
        (response) => {
          this.vacation = response;
        },
        (error) => {},
        () => {
          // Calculate remaining balance
          this.vacation.forEach((element) => {
            var vacationUpdated = new Date(element.updated_until);

            this.vactionUpdatedUntil = vacationUpdated;
            this.vacationPaid = element.paid;
            this.vacationUnpaid = element.unpaid;
            this.vacationSaved = element.saved;
            this.vacationTotal =
              Number(this.vacationPaid) +
              Number(this.vacationUnpaid) +
              Number(this.vacationSaved);
          });
        }
      );
  }

  loadCar() {
    var carFee = this.globalVariables.selectedUser.car_leasing_fee;
    if (!(carFee == null || carFee == '0' || carFee == '')) {
      this.hasCar = true;

      this.carLeasingFee = Number(
        this.globalVariables.selectedUser.car_leasing_fee
      );
      this.carContribution = Number(
        this.globalVariables.selectedUser.car_leasing_contribution
      );
      this.carCost = this.carLeasingFee - this.carContribution;
    }
  }
}

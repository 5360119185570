export class Payout {
  amountIncludingFees: number;
  amountExcludingFees: number;
  tax: number;
  vacationPay: number;

  constructor(
    amountIncludingFees: number,
    amountExcludingFees: number,
    tax: number,
    vacationPay: number
  ) {
    this.amountIncludingFees = amountIncludingFees;
    this.amountExcludingFees = amountExcludingFees;
    this.tax = tax;
    this.vacationPay = vacationPay;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Billable } from '../classes/Billable';
import { InternalProject } from '../classes/InternalProject';
import { PayoutRequest } from '../classes/PayoutRequest';
import { TimeEntry } from '../classes/TimeEntry';
import { Transaction } from '../classes/Transaction';
import { User } from '../classes/User';
import { Wellness } from '../classes/Wellness';
import { WintEmployee } from '../classes/WintEmployee';
import { Equipment } from '../classes/Equipment';
import { Education } from '../classes/Education';
import { Vacation } from '../classes/Vacation';

const headerDict = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
};

const requestOptions = {
  headers: new HttpHeaders(headerDict),
};

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private http: HttpClient) {}

  baseUrl: string = environment.sallaryManagerUrl;

  urlUSers: string = 'user';
  urlTransactions: string = 'transaction';
  urlPayoutRequest: string = 'payoutrequest';
  urlInternalTime: string = 'internaltime';
  urlBillableTime: string = 'billable';
  urlProjects: string = 'project';
  urlWintEmployees: string = 'wintEmployees';
  urlWintSalaryDeviation: string = 'wintSalaryDeviation';
  urlWellness: string = 'wellness';
  urlEducation: string = 'education';
  urlEquipment: string = 'equipment';
  urlVacation: string = 'vacation';

  getUsers() {
    return this.http.get<User[]>(this.baseUrl + this.urlUSers, {
      headers: headerDict,
    });
  }

  getInternalProjects() {
    return this.http.get<InternalProject[]>(this.baseUrl + this.urlProjects, {
      headers: headerDict,
    });
  }

  getUser(user_mail: string) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    return this.http.get<User>(this.baseUrl + this.urlUSers, {
      params: params,
      headers: headerDict,
    });
  }

  getTransactions(user_mail, fromDate, toDate, type, showValid) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    if (fromDate) {
      params = params.append('fromdate', fromDate);
    }
    if (toDate) {
      params = params.append('todate', toDate);
    }
    if (type) {
      params = params.append('transactiontype', type);
    }

    if (showValid) {
      params = params.append('showValid', 'true');
    }

    return this.http.get<Transaction[]>(this.baseUrl + this.urlTransactions, {
      params: params,
      headers: headerDict,
    });
  }

  createTransaction(transaction: Transaction) {
    let json_data = JSON.stringify(transaction);

    return this.http.post(
      this.baseUrl + this.urlTransactions,
      json_data,
      requestOptions
    );
  }

  deleteTransaction(user_mail, key) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('key', key);

    return this.http.delete(this.baseUrl + this.urlTransactions, {
      params: params,
      headers: headerDict,
    });
  }

  updateTransaction(transaction) {
    let json_data = JSON.stringify(transaction);

    return this.http.put(
      this.baseUrl + this.urlTransactions,
      json_data,
      requestOptions
    );
  }

  createPayoutRequest(user_mail, user_name, type, amount, comment) {
    let data = {
      user_mail: user_mail,
      user_name: user_name,
      amount: amount,
      comment: comment,
      type: type,
    };
    let json_data = JSON.stringify(data);

    return this.http.post(
      this.baseUrl + this.urlPayoutRequest,
      json_data,
      requestOptions
    );
  }

  getPayoutRequests(user_mail: string) {
    let params = new HttpParams();
    if (user_mail) {
      params = params.append('user_mail', user_mail);
    }

    return this.http.get<PayoutRequest[]>(
      this.baseUrl + this.urlPayoutRequest,
      {
        params: params,
        headers: headerDict,
      }
    );
  }

  updatePayoutRequests(user_mail, key, data) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('key', key);
    let json_data = JSON.stringify(data);

    return this.http.put(this.baseUrl + this.urlPayoutRequest, json_data, {
      headers: headerDict,
      params: params,
    });
  }

  deletePayoutRequests(user_mail, key) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('key', key);

    return this.http.delete(this.baseUrl + this.urlPayoutRequest, {
      params: params,
      headers: headerDict,
    });
  }

  getInternalTime(
    fromDate,
    toDate,
    internal: boolean,
    education: boolean,
    absence: boolean
  ) {
    let params = new HttpParams();
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);
    params = params.append('internal', String(internal));
    params = params.append('education', String(education));
    params = params.append('absence', String(absence));

    return this.http.get<TimeEntry[]>(this.baseUrl + this.urlInternalTime, {
      params: params,
      headers: headerDict,
    });
  }

  getBillable(fromDate, toDate) {
    let params = new HttpParams();
    params = params.append('fromDate', fromDate);
    params = params.append('toDate', toDate);

    return this.http.get<Billable[]>(this.baseUrl + this.urlBillableTime, {
      params: params,
      headers: headerDict,
    });
  }

  getWintEmployees() {
    return this.http.get<WintEmployee[]>(this.baseUrl + this.urlWintEmployees, {
      headers: headerDict,
    });
  }

  createSalaryDeviation(json_data) {
    return this.http.post(
      this.baseUrl + this.urlWintSalaryDeviation,
      json_data,
      requestOptions
    );
  }

  getWellness(user_mail, fromDate, toDate) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    if (fromDate) {
      params = params.append('fromdate', fromDate);
    }
    if (toDate) {
      params = params.append('todate', toDate);
    }

    return this.http.get<Wellness[]>(this.baseUrl + this.urlWellness, {
      params: params,
      headers: headerDict,
    });
  }

  getWellnessByYear(user_mail, year) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('year', year);
    return this.http.get<Wellness[]>(this.baseUrl + this.urlWellness, {
      params: params,
      headers: headerDict,
    });
  }

  createWellness(transaction: Wellness) {
    let json_data = JSON.stringify(transaction);

    return this.http.post(
      this.baseUrl + this.urlWellness,
      json_data,
      requestOptions
    );
  }

  deleteWellness(user_mail, key) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('key', key);

    return this.http.delete(this.baseUrl + this.urlWellness, {
      params: params,
      headers: headerDict,
    });
  }

  getEducation(user_mail, fromDate, toDate) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    if (fromDate) {
      params = params.append('fromdate', fromDate);
    }
    if (toDate) {
      params = params.append('todate', toDate);
    }

    return this.http.get<Education[]>(this.baseUrl + this.urlEducation, {
      params: params,
      headers: headerDict,
    });
  }

  getEducationByYear(user_mail, year) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('year', year);
    return this.http.get<Education[]>(this.baseUrl + this.urlEducation, {
      params: params,
      headers: headerDict,
    });
  }

  createEducation(transaction: Education) {
    let json_data = JSON.stringify(transaction);

    return this.http.post(
      this.baseUrl + this.urlEducation,
      json_data,
      requestOptions
    );
  }

  deleteEducation(user_mail, key) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('key', key);

    return this.http.delete(this.baseUrl + this.urlEducation, {
      params: params,
      headers: headerDict,
    });
  }

  getEquipment(user_mail, fromDate, toDate) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    if (fromDate) {
      params = params.append('fromdate', fromDate);
    }
    if (toDate) {
      params = params.append('todate', toDate);
    }

    return this.http.get<Equipment[]>(this.baseUrl + this.urlEquipment, {
      params: params,
      headers: headerDict,
    });
  }

  createEquipment(transaction: Equipment) {
    let json_data = JSON.stringify(transaction);

    return this.http.post(
      this.baseUrl + this.urlEquipment,
      json_data,
      requestOptions
    );
  }

  deleteEquipment(user_mail, key) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);
    params = params.append('key', key);

    return this.http.delete(this.baseUrl + this.urlEquipment, {
      params: params,
      headers: headerDict,
    });
  }

  getVacation(user_mail) {
    let params = new HttpParams();
    params = params.append('user_mail', user_mail);

    return this.http.get<Vacation[]>(this.baseUrl + this.urlVacation, {
      params: params,
      headers: headerDict,
    });
  }
}

<ng-http-loader
  [backdrop]="false"
  [backgroundColor]="'#00ffd1'"
  [debounceDelay]="100"
  [extraDuration]="300"
  [minDuration]="300"
  [opacity]="0.6"
  [backdropBackgroundColor]="'#00ffd1'"
  spinner="sk-three-bounce"
></ng-http-loader>
<!-- Table -->
<mat-table
  matSort
  matSortActive="req_date"
  matSortDirection="desc"
  [dataSource]="dataSource"
  [multiTemplateDataRows]="true"
  class="mat-elevation-z8"
>
  <!-- Date Column -->
  <ng-container matColumnDef="req_date">
    <mat-header-cell mat-sort-header disableClear *matHeaderCellDef
      >Datum</mat-header-cell
    >
    <mat-cell *matCellDef="let element">
      {{ element.req_date | dateTimeFormatFilter : "YYYY-MM-DD" }}
    </mat-cell>
  </ng-container>

  <!-- Comment Column -->
  <ng-container matColumnDef="comment">
    <mat-header-cell fxFlex="80" *matHeaderCellDef>Meddelande</mat-header-cell>
    <mat-cell fxFlex="80" *matCellDef="let element">
      <span class="ellipsis"> {{ element.comment }}</span>
    </mat-cell>
  </ng-container>

  <!-- User name Column -->
  <ng-container matColumnDef="user_name">
    <mat-header-cell fxFlex="20" *matHeaderCellDef>Anställd</mat-header-cell>
    <mat-cell fxFlex="20" *matCellDef="let element">
      <span class="ellipsis"> {{ element.user_name }}</span>
    </mat-cell>
  </ng-container>

  <!-- Amount Column -->
  <ng-container matColumnDef="amount">
    <mat-header-cell fxFlex="noshrink" *matHeaderCellDef
      >Belopp</mat-header-cell
    >
    <mat-cell fxFlex="noshrink" *matCellDef="let element">
      {{ element.amount | number : "0.0-0" : "se-SE" }}
    </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <mat-header-cell fxFlex="noshrink" *matHeaderCellDef
      >Status</mat-header-cell
    >
    <mat-cell fxFlex="noshrink" *matCellDef="let element">
      <mat-icon
        *ngIf="element.type == 'sal_out'"
        [ngClass]="{
          approvedIcon: element.status == 'approved',
          pendingIcon: element.status == 'reg'
        }"
        >monetization_on</mat-icon
      >
      <mat-icon
        *ngIf="element.type == 'pen'"
        [ngClass]="{
          approvedIcon: element.status == 'approved',
          pendingIcon: element.status == 'reg'
        }"
        >person</mat-icon
      >
    </mat-cell>
  </ng-container>

  <!-- Detail expand -->
  <ng-container matColumnDef="expandedDetail">
    <mat-cell
      style="padding-left: 10px"
      *matCellDef="let element"
      [@expandableRow]="element == expandedElement ? 'expanded' : 'collapsed'"
    >
      <div *ngIf="element === expandedElement" class="element-detail">
        <div>
          <div style="padding-right: 10px; border-right: solid 1px">
            <div class="expanded-detail-group">
              <div class="expanded-detail-title">Uttag</div>
              <div>
                {{
                  element.amount
                    | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                }}
              </div>
            </div>
            <div class="expanded-detail-group">
              <div
                *ngIf="element.type == 'sal_out'"
                class="expanded-detail-title"
              >
                Arbetsgivaravgift
              </div>
              <div *ngIf="element.type == 'pen'" class="expanded-detail-title">
                Särskild löneskatt
              </div>

              <div *ngIf="element.type == 'sal_out'">
                {{
                  -element.tax
                    | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                }}
              </div>
              <div *ngIf="element.type == 'pen'">
                {{
                  -element.tax
                    | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                }}
              </div>
            </div>
            <div class="expanded-detail-group">
              <div
                *ngIf="element.type == 'sal_out'"
                class="expanded-detail-title"
              >
                Semestertillägg
              </div>

              <div *ngIf="element.type == 'sal_out'">
                {{
                  -element.vacation_pay
                    | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                }}
              </div>
            </div>
            <div class="expanded-detail-group element-deposit">
              <div class="expanded-detail-title">Utbetalning</div>
              <div *ngIf="element.type == 'sal_out'">
                {{
                  element.payout
                    | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                }}
              </div>
              <div *ngIf="element.type == 'pen'">
                {{
                  element.payout
                    | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
                }}
              </div>
            </div>
            <div style="display: inline" *ngIf="element.status == 'reg'">
              <button
                mat-flat-button
                color="warn"
                (click)="onClickDelete(element)"
              >
                Radera
              </button>
            </div>
            <span
              style="padding-left: 20px"
              *ngIf="
                element.status == 'reg' && globalVariables.isLoggedinUserAdmin()
              "
              (click)="onClickApprovePayout(element)"
            >
              <button mat-flat-button color="primary">Godkänn</button>
            </span>
            <span
              style="padding-left: 20px; width: 20px"
              *ngIf="
                element.status == 'reg' && globalVariables.isLoggedinUserAdmin()
              "
            >
              <mat-form-field appearance="outline">
                <mat-label>Utbetalningsmånad</mat-label>

                <input
                  matInput
                  inputmode="numeric"
                  pattern="[0-9]"
                  type="number"
                  type="text"
                  autocomplete="off"
                  [value]="payoutMonth"
                />
              </mat-form-field>
            </span>
          </div>
          <div style="padding-bottom: 10px"></div>
        </div>

        <div style="padding-left: 10px">
          {{ element.comment }}
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="element-row"
    [class.expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="detail-row"
    style="border: solid blueviolet"
  ></tr>
</mat-table>
<div
  *ngIf="dataSourceEmpty"
  style="width: 100%; text-align: center; padding-top: 10px"
>
  Inga begärda utbetalningar
</div>

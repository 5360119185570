<ng-http-loader
  [backdrop]="false"
  [backgroundColor]="'#00ffd1'"
  [debounceDelay]="100"
  [extraDuration]="300"
  [minDuration]="300"
  [opacity]="0.6"
  [backdropBackgroundColor]="'#00ffd1'"
  spinner="sk-three-bounce"
></ng-http-loader>

<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-description>
        Förmåner
        <mat-icon>card_giftcard</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-benefit-admin></app-benefit-admin>
  </mat-expansion-panel>
</mat-accordion>

<div style="margin-top: 10px"></div>

<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        Skapa manuellt
        <mat-icon>edit</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayout.lt-sm="column"
      fxLayoutGap="0px"
    >
      <div>
        <!-- Employee dropdown -->
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Anställd</mat-label>
          <mat-select (selectionChange)="onSelectUser($event)">
            <mat-option
              *ngFor="let userObj of globalVariables.allUsers"
              [value]="userObj"
            >
              {{ userObj.user_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Transaction type dropdown -->
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Typ</mat-label>
          <mat-select
            class="select-font-size"
            [(value)]="selectedTransactionType"
            (selectionChange)="onSelectType($event)"
          >
            <mat-select-trigger>
              {{ selectedTransactionType.viewValue }}
            </mat-select-trigger>
            <mat-option
              class="transactionTypeOption"
              *ngFor="let transactionType of globalVariables.transactionTypes"
              [value]="transactionType"
            >
              <mat-icon
                *ngIf="transactionType.icon_type == 'ico'"
                class="transactionTypeOptionIcon"
                >{{ transactionType.icon }}</mat-icon
              >
              <mat-icon
                *ngIf="transactionType.icon_type == 'svg'"
                [svgIcon]="transactionType.icon"
                class="transactionTypeOptionIcon"
              ></mat-icon
              >{{ transactionType.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <!-- Date selector -->
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Datum</mat-label>
          <input
            matInput
            [matDatepicker]="picker2"
            [(ngModel)]="transaction.transaction_date"
            (dateChange)="calculateFields()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Kommentar</mat-label>
          <input
            matInput
            name="comment"
            [(ngModel)]="transaction.comment"
            (change)="calculateFields()"
          />
        </mat-form-field>
      </div>
      <div>
        <!-- Internal comment -->
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Intern kommentar</mat-label>
          <input
            matInput
            name="internal_comment"
            [(ngModel)]="transaction.internal_comment"
            (change)="calculateFields()"
          />
        </mat-form-field>
        <!-- Invoiced input -->
        <mat-form-field
          class="form-field"
          *ngIf="selectedTransactionType.value == 'sal_in_ext'"
          appearance="fill"
        >
          <mat-label>Fakturerat</mat-label>
          <input
            matInput
            inputmode="numeric"
            pattern="[0-9]"
            type="number"
            name="invoiced"
            [(ngModel)]="transaction.invoiced"
            (change)="calculateFields()"
          />
          <span matSuffix>&nbsp;Kr</span>
        </mat-form-field>
      </div>
      <div>
        <!-- Breaking limit input -->
        <mat-form-field
          class="form-field"
          *ngIf="selectedTransactionType.value == 'sal_in_ext'"
          appearance="fill"
        >
          <mat-label>Brytgräns</mat-label>
          <input
            matInput
            inputmode="numeric"
            pattern="[0-9]"
            type="number"
            name="breaking_limit"
            [(ngModel)]="transaction.breaking_limit"
            (change)="calculateFields()"
          />
          <span matSuffix>&nbsp;Kr</span>
        </mat-form-field>
        <!-- Surplus input -->
        <mat-form-field
          class="form-field"
          *ngIf="selectedTransactionType.value == 'sal_in_ext'"
          appearance="fill"
        >
          <mat-label>Överskott</mat-label>
          <input matInput [(ngModel)]="transaction.surplus" />
          <span matSuffix>&nbsp;Kr</span>
        </mat-form-field>
      </div>
      <div>
        <!-- Factor input -->
        <mat-form-field
          class="form-field"
          *ngIf="selectedTransactionType.value == 'sal_in_ext'"
          appearance="fill"
        >
          <mat-label>Ersättningsfaktor</mat-label>
          <input
            matInput
            inputmode="numeric"
            pattern="[0-9]"
            type="number"
            name="factor"
            [(ngModel)]="transaction.factor"
            (change)="calculateFields()"
          />
          <span matSuffix>&nbsp;%</span>
        </mat-form-field>
        <!-- Amount input -->
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>Summa</mat-label>
          <input
            [readonly]="selectedTransactionType.value == 'sal_in_ext'"
            matInput
            name="amount"
            inputmode="numeric"
            pattern="[0-9]"
            type="number"
            min="0"
            [(ngModel)]="transaction.amount"
            (change)="calculateFields()"
          />
          <span matSuffix>&nbsp;Kr</span>
        </mat-form-field>
      </div>
      <!-- Payout tax -->
      <div
        *ngIf="
          selectedTransactionType.value == 'pen' ||
          selectedTransactionType.value == 'sal_out'
        "
      >
        <mat-label>Skatt</mat-label>
        {{
          transaction.payout_tax
            | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
        }}
      </div>
      <div *ngIf="selectedTransactionType.value == 'sal_out'">
        <mat-label>Semestertillägg</mat-label>
        {{
          transaction.vacation_pay
            | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
        }}
      </div>
      <!-- Payout -->
      <div
        *ngIf="
          selectedTransactionType.value == 'pen' ||
          selectedTransactionType.value == 'sal_out'
        "
      >
        <mat-label>Utbetalning</mat-label>

        {{
          transaction.payout
            | currency : "SEK" : "symbol-narrow" : "0.0-0" : "se-SE"
        }}
      </div>
      <div>
        <button
          mat-button
          mat-flat-button
          color="primary"
          (click)="createTransaction()"
          class="standard-button"
        >
          Skapa
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div style="margin-top: 10px"></div>

<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-description>
        Hämta data från Harvest
        <mat-icon>input</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start">
      <div style="padding-right: 20px">
        <mat-form-field class="form-field-long" appearance="standard">
          <mat-label>Ange datumintervall</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              placeholder="Start date"
              [(ngModel)]="startDate"
            />
            <input matEndDate placeholder="End date" [(ngModel)]="endDate" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div>
        <button
          [disabled]="!endDate"
          mat-button
          mat-flat-button
          color="primary"
          (click)="onClickInternal()"
          class="harvest-button"
        >
          Interntid
        </button>
      </div>
      <div>
        <button
          [disabled]="!endDate"
          mat-button
          mat-flat-button
          color="primary"
          (click)="onClickAbsence()"
          class="harvest-button"
        >
          Frånvaro
        </button>
      </div>
      <div>
        <button
          mat-button
          [disabled]="!endDate"
          mat-flat-button
          color="primary"
          (click)="onClickBillable()"
          class="harvest-button"
        >
          Fakturerat
        </button>
      </div>

      <div>
        <button
          mat-button
          mat-flat-button
          color="primary"
          [disabled]="!isAnySelected()"
          (click)="sendToWint()"
          class="wint-button"
        >
          Skicka till Wint
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<!-- Table -->
<table
  mat-table
  [dataSource]="dataSource"
  [multiTemplateDataRows]="true"
  matSort
  matSortActive="date"
  matSortDirection="desc"
  class="mat-elevation-z8"
>
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        color="primary"
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        *ngIf="!(row.task_id == '7820447' && !row.notes)"
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Datum
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.date | dateTimeFormatFilter : "YYYY-MM-DD" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="user_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Anställd
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.user_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="project_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Projekt
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.project_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="task_name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Task</th>
    <td mat-cell *matCellDef="let element">
      {{ element.task_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Kommentar</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon
        matTooltip="Namn på barn saknas i Harvest"
        color="warn"
        *ngIf="element.task_id == '7820447' && !element.notes"
        >warning</mat-icon
      >
      {{ element.notes }}
    </td>
  </ng-container>

  <ng-container matColumnDef="hours">
    <th mat-header-cell *matHeaderCellDef>Timmar</th>
    <td mat-cell *matCellDef="let element">
      {{ element.hours }}
      <mat-icon
        matTooltip="4 timmars semester måste hanteras manuellt i avvikelsehanteringen hos Wint"
        color="warn"
        *ngIf="element.task_id == '7820445' && element.hours != '8'"
        >warning</mat-icon
      >
    </td>
  </ng-container>
  <ng-container matColumnDef="hourly_rate_1">
    <th mat-header-cell *matHeaderCellDef>Timersättning normal</th>
    <td mat-cell *matCellDef="let element">
      {{ element.hourly_rate_1 }}
    </td>
  </ng-container>
  <ng-container matColumnDef="total_amount_1">
    <th mat-header-cell *matHeaderCellDef>Summa normal</th>
    <td mat-cell *matCellDef="let element">
      <span style="width: 40px; display: inline-block">
        {{ element.total_amount_1 }}
      </span>
      <span>
        <button
          matTooltip="Lägg till ersättning"
          mat-icon-button
          [disabled]="element.isProcessed"
          color="primary"
          (click)="onClickCreateTransaction('total_amount_1', element)"
        >
          <mat-icon *ngIf="!element.total_amount_1_checked"
            >add_circle_outline</mat-icon
          >
          <mat-icon *ngIf="element.total_amount_1_checked"
            >check_circle_outline</mat-icon
          >
        </button>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="hourly_rate_2">
    <th mat-header-cell *matHeaderCellDef>Timersättning hög</th>
    <td mat-cell *matCellDef="let element">
      {{ element.hourly_rate_2 }}
    </td>
  </ng-container>
  <ng-container matColumnDef="total_amount_2">
    <th mat-header-cell *matHeaderCellDef>Summa hög</th>
    <td mat-cell *matCellDef="let element">
      <span style="width: 40px; display: inline-block">
        {{ element.total_amount_2 }}
      </span>
      <span>
        <button
          matTooltip="Lägg till ersättning"
          mat-icon-button
          [disabled]="element.isProcessed"
          color="primary"
          (click)="onClickCreateTransaction('total_amount_2', element)"
        >
          <mat-icon *ngIf="!element.total_amount_2_checked"
            >add_circle_outline</mat-icon
          >
          <mat-icon *ngIf="element.total_amount_2_checked"
            >check_circle_outline</mat-icon
          >
        </button>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="billable_hours">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Fakturerade timmar
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.billable_hours }}
    </td>
  </ng-container>
  <ng-container matColumnDef="billable_amount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      Fakturerat belopp
    </th>
    <td mat-cell *matCellDef="let element">
      <span style="width: 50px; display: inline-block">
        {{ element.billable_amount | number : "0.0-0" : "se-SE" }}
      </span>
      <span>
        <button
          matTooltip="Lägg till ersättning"
          [disabled]="element.isProcessed"
          mat-icon-button
          color="primary"
          (click)="onClickCreateBillable(element)"
        >
          <mat-icon *ngIf="!element.isProcessed">add_circle_outline</mat-icon>
          <mat-icon *ngIf="element.isProcessed">check_circle_outline</mat-icon>
        </button>
      </span>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  snackBar;
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    console.log(error);
    this.snackBar = this.injector.get(MatSnackBar);
    if (error.message) {
      this.openSnackBar(error.message);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 2000,
      panelClass: ['mat-toolbar', 'mat-warn'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}

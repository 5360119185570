import { Component, Input, OnInit } from '@angular/core';
import { GlobalVariables } from '../../../core/global/globalvariables';
import { RestService } from 'src/app/core/services/rest.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { Transaction } from 'src/app/core/classes/Transaction';

import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() type: string;

  constructor(
    private restService: RestService,
    public globalVariables: GlobalVariables
  ) {}

  transactions: Transaction[] = [];
  today = new Date();

  monthNames = [
    'Jan',
    'Feb',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Aug',
    'Sept',
    'Okt',
    'Nov',
    'Dec',
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let decimalPipe = new DecimalPipe('se-SE');
          return decimalPipe.transform(tooltipItem.yLabel, '1.0-0');
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: '#f2dfa1de', // x axe labels (can be hexadecimal too)
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: '#f2dfa1de', // y axes numbers color (can be hexadecimal too)
            min: 0,
            beginAtZero: true,
            callback: (dataLabel, index) => {
              let decimalPipe = new DecimalPipe('se-SE');
              return decimalPipe.transform(dataLabel, '1.0-0');
            },
          },

          scaleLabel: {
            display: false,
            labelString: 'Kronor',
            fontColor: '#f2dfa1de', // y axe label color (can be hexadecimal too)
          },
        },
      ],
    },
    legend: {
      display: true,
      labels: {
        fontColor: '#f2dfa1de', // legend color (can be hexadecimal too)
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Color[] = [];

  public barChartData: ChartDataSets[] = [];

  ngOnInit() {
    if (this.type == 'income') {
      this.barChartData = [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Externt',
          stack: 'a',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Internt',
          stack: 'a',
        },
      ];
      this.barChartColors = [
        { backgroundColor: '#00ffd1' },
        { backgroundColor: '#de7d77' },
      ];
      this.loadTransaction();
    } else if (this.type == 'expenses') {
      this.barChartData = [
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Löneuttag',
          stack: 'a',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Pension',
          stack: 'a',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Inköp',
          stack: 'a',
        },
        {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          label: 'Bil',
          stack: 'a',
        },
      ];

      this.barChartColors = [
        { backgroundColor: '#00ffd1' },
        { backgroundColor: '#de7d77' },
        { backgroundColor: '#557e94' },
        { backgroundColor: '#f2dfa1' },
      ];
      this.loadExpenses();
    }
  }

  loadTransaction() {
    var fromDate = new Date(this.today);
    fromDate.setFullYear(this.today.getFullYear() - 1);
    fromDate.setMonth(this.today.getMonth());
    fromDate.setDate(1);

    var toDate = new Date(this.today.getFullYear(), this.today.getMonth(), 0);

    this.restService
      .getTransactions(
        this.globalVariables.selectedUser.user_mail,
        this.formatDate(fromDate),
        this.formatDate(toDate),
        '',
        'true'
      )
      .subscribe(
        (response) => {
          this.transactions = response;
        },
        (error) => {},
        () => {
          for (let index = 0; index < 12; index++) {
            let displayDate = new Date(fromDate);
            displayDate.setMonth(fromDate.getMonth() + index);
            this.barChartLabels[index] =
              '' +
              displayDate.getFullYear() +
              ' ' +
              this.monthNames[displayDate.getMonth()];
          }

          this.transactions.forEach((element) => {
            var barChartDataSerie = 0;
            switch (element.transaction_type) {
              case 'sal_in_int':
                barChartDataSerie = 1;
              case 'sal_in_ext': {
                let transactionDate = new Date(element.transaction_date);
                let transactionMonth = transactionDate.getMonth();
                let barChartDataIndex = transactionMonth - fromDate.getMonth();
                if (barChartDataIndex < 0) {
                  barChartDataIndex = barChartDataIndex + 12;
                }
                let currentMonthAmount = Number(
                  this.barChartData[barChartDataSerie].data[barChartDataIndex]
                );
                let newMonthAmount = element.amount;

                this.barChartData[barChartDataSerie].data[barChartDataIndex] =
                  Number(newMonthAmount) + currentMonthAmount;
              }
            }
          });
        }
      );
  }

  loadExpenses() {
    var fromDate = new Date(this.today);
    fromDate.setFullYear(this.today.getFullYear() - 1);
    fromDate.setDate(1);

    var toDate = new Date(this.today.getFullYear(), this.today.getMonth(), 0);

    this.restService
      .getTransactions(
        this.globalVariables.selectedUser.user_mail,
        this.formatDate(fromDate),
        this.formatDate(toDate),
        '',
        'true'
      )
      .subscribe(
        (response) => {
          this.transactions = response;
        },
        (error) => {},
        () => {
          for (let index = 0; index < 12; index++) {
            let displayDate = new Date(fromDate);
            displayDate.setMonth(fromDate.getMonth() + index);
            this.barChartLabels[index] =
              '' +
              displayDate.getFullYear() +
              ' ' +
              this.monthNames[displayDate.getMonth()];
          }

          this.transactions.forEach((element) => {
            var barChartDataSerie = -1;
            var newMonthAmount = 0;

            if (element.transaction_type == 'sal_out') {
              barChartDataSerie = 0;
              newMonthAmount = -(
                Number(element.amount)
                // newMonthAmount = -(
                // Number(element.amount) + Number(element.payout_tax)
              );
            } else if (element.transaction_type == 'pen') {
              barChartDataSerie = 1;
              newMonthAmount = -(
                Number(element.amount)
                //newMonthAmount = -(
                // Number(element.amount) + Number(element.payout_tax)
              );
            } else if (element.transaction_type == 'pur') {
              barChartDataSerie = 2;
              newMonthAmount = -element.amount;
            } else if (element.transaction_type == 'car') {
              barChartDataSerie = 3;
              newMonthAmount = -element.amount;
            }
            if (barChartDataSerie != -1) {
              let transactionDate = new Date(element.transaction_date);
              let transactionMonth = transactionDate.getMonth();
              let barChartDataIndex = transactionMonth - fromDate.getMonth();
              if (barChartDataIndex < 0) {
                barChartDataIndex = barChartDataIndex + 12;
              }
              let currentMonthAmount = Number(
                this.barChartData[barChartDataSerie].data[barChartDataIndex]
              );

              this.barChartData[barChartDataSerie].data[barChartDataIndex] =
                Number(newMonthAmount) + currentMonthAmount;
            }
          });
        }
      );
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
}

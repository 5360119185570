<div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="flex-start">
  <div>
    <mat-form-field class="form-field" appearance="fill">
      <mat-label>Anställd</mat-label>
      <mat-select [(value)]="benefit.user">
        <mat-option
          *ngFor="let userObj of globalVariables.allUsers"
          [value]="userObj.user_mail"
        >
          {{ userObj.user_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="form-field" appearance="fill">
      <mat-label>Typ</mat-label>
      <mat-select [(value)]="benefit.type">
        <mat-option
          *ngFor="let benefitOption of benefitTypes | keyvalue"
          [value]="benefitOption.key"
        >
          {{ benefitOption.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="form-field" appearance="fill">
      <mat-label>Datum</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="benefit.date" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="form-field" appearance="fill">
      <mat-label>Avser år</mat-label>
      <input matInput name="year" [(ngModel)]="benefit.year" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="form-field" appearance="fill">
      <mat-label>Kommentar</mat-label>
      <input matInput name="comment" [(ngModel)]="benefit.comment" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="form-field" appearance="fill">
      <mat-label>Summa</mat-label>
      <input
        matInput
        name="amount"
        inputmode="numeric"
        pattern="[0-9]"
        type="number"
        min="0"
        [(ngModel)]="benefit.amount"
      />
      <span matSuffix>&nbsp;Kr</span>
    </mat-form-field>
  </div>
  <div>
    <span>
      <button
        mat-button
        mat-flat-button
        color="primary"
        (click)="onCreateTransaction()"
      >
        Skapa
      </button>
    </span>
  </div>
</div>

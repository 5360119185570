import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PayoutRequest } from 'src/app/core/classes/PayoutRequest';
import { Transaction } from 'src/app/core/classes/Transaction';
import { WintEmployee } from 'src/app/core/classes/WintEmployee';
import { RestService } from 'src/app/core/services/rest.service';
import { GlobalVariables } from '../../core/global/globalvariables';
import { expandableRowAnimation } from './expandable-row.animation';
import { GlobalFunctions } from 'src/app/core/global/globalFunctions';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss'],
  animations: [expandableRowAnimation],
})
export class PayoutComponent implements OnInit {
  constructor(
    private restService: RestService,
    public globalVariables: GlobalVariables,
    private snackBar: MatSnackBar,
    public globalFunctions: GlobalFunctions
  ) {}
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  payoutRequests: PayoutRequest[] = [];
  expandedElement: PayoutRequest;
  keyfields = 0;

  payoutMonth = new Date().getMonth() + 1;

  displayedColumns: string[] = ['req_date', 'status', 'comment', 'amount'];
  dataSourceEmpty = true;

  wintEmployees: WintEmployee[] = [];

  ngOnInit(): void {
    this.loadPayoutRequests();
    if (this.globalVariables.isLoggedinUserManager())
      [
        (this.displayedColumns = [
          'req_date',
          'status',
          'user_name',
          'comment',
          'amount',
        ]),
      ];

    if (this.globalVariables.isLoggedinUserAdmin()) {
      this.restService.getWintEmployees().subscribe((response) => {
        this.wintEmployees = response;
      });
    }
  }

  onClickDelete(event: any) {
    this.restService
      .deletePayoutRequests(event.user_mail, event.key)
      .toPromise()
      .then(() => {
        this.dataSource.data = this.dataSource.data.filter((i) => i !== event);
        this.snackBar.open('Begäran raderad', 'OK', {
          duration: 2000,
          panelClass: ['mat-toolbar', 'mat-primary'],
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        if (this.dataSource.data.length == 0) {
          this.dataSourceEmpty = true;
        }
      });
  }

  onClickApprovePayout(event: any) {
    let newTransaction = this.getTransactionObject();
    newTransaction.user_mail = event.user_mail;
    newTransaction.status = 'valid';
    newTransaction.transaction_date = this.getPayoutDate();
    newTransaction.internal_comment = 'Egen begäran';
    newTransaction.amount = '' + -Math.round(Number(event.amount));

    let payoutObj = this.globalFunctions.getPayout(
      event.type,
      parseFloat(event.amount),
      true
    );

    newTransaction.transaction_type = event.type;
    newTransaction.payout = '' + -Math.round(payoutObj.amountExcludingFees);
    newTransaction.payout_tax = '' + Math.round(payoutObj.tax);

    if (event.type == 'sal_out') {
      newTransaction.comment = 'Löneuttag';
      newTransaction.vacation_pay = '' + Math.round(payoutObj.vacationPay);
    } else {
      newTransaction.comment = 'Pensionsuttag';
    }

    if (event.type == 'sal_out') {
      let wintEmployee = this.wintEmployees.find(
        (i) => i.Name === event.user_name
      );

      var newProvision = new provision();
      newProvision.description = 'Uttag rörlig ersättning';
      newProvision.sum = Math.round(payoutObj.amountExcludingFees);

      var newDeviation = new deviation();
      newDeviation.deviationType = 11;
      newDeviation.personId = wintEmployee.Id;
      newDeviation.yearAndMonth = this.formatYearAndMonth(new Date());
      newDeviation.provisions = [newProvision];

      let json = JSON.stringify(newDeviation);

      this.restService
        .createSalaryDeviation(json)
        .toPromise()
        .then(() => {
          // Create transaction
          this.restService
            .createTransaction(newTransaction)
            .toPromise()
            .then(() => {
              // Set status of payout request to approved
              this.restService
                .updatePayoutRequests(event.user_mail, event.key, {
                  status: 'approved',
                })
                .toPromise()
                .then(() => {
                  this.snackBar.open('Transaktionen skapad', 'OK', {
                    duration: 2000,
                    panelClass: ['mat-toolbar', 'mat-primary'],
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                  });
                });
            });
        });
    } else {
      // Create transaction
      this.restService
        .createTransaction(newTransaction)
        .toPromise()
        .then(() => {
          // Set status of payout request to approved
          this.restService
            .updatePayoutRequests(event.user_mail, event.key, {
              status: 'approved',
            })
            .toPromise()
            .then(() => {
              this.snackBar.open('Transaktionen skapad', 'OK', {
                duration: 2000,
                panelClass: ['mat-toolbar', 'mat-primary'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            });
        });
    }
  }

  getTransactionObject() {
    let newTransaction: Transaction = {
      user_mail: '',
      status: '',
      key: '',
      breaking_limit: '',
      comment: '',
      factor: '',
      invoiced: '',
      surplus: '',
      transaction_date: '',
      amount: '',
      transaction_type: '',
      payout_tax: '',
      vacation_pay: '',
      payout: '',
      internal_comment: '',
      harvest_entry_id: '',
      hours: '',
      hourly_rate: '',
    };

    return newTransaction;
  }

  checkOverflow(element) {
    if (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    ) {
      return true;
    } else {
      return false;
    }
  }

  private loadPayoutRequests() {
    let user_mail;
    if (!this.globalVariables.isLoggedinUserManager()) {
      user_mail = this.globalVariables.selectedUser.user_mail;
    }
    this.restService.getPayoutRequests(user_mail).subscribe(
      (response) => {
        this.payoutRequests = response;
      },
      () => {},
      () => {
        this.dataSource = new MatTableDataSource(this.payoutRequests);
        this.dataSourceEmpty = true;
        this.globalVariables.payoutReqToApprove = 0;
        if (this.dataSource.data) {
          this.dataSource.data.forEach((element) => {
            let payout = this.globalFunctions.getPayout(
              element.type,
              parseFloat(element.amount),
              true
            );

            element.tax = payout.tax;
            if (element.req_date >= '2024-01-01') {
              element.vacation_pay = payout.vacationPay;
              element.payout = payout.amountExcludingFees;
            } else {
              element.payout = payout.amountExcludingFees + payout.vacationPay;
            }

            if (
              this.globalVariables.isLoggedinUserAdmin() &&
              element.status == 'reg'
            ) {
              this.globalVariables.payoutReqToApprove++;
            }
          });

          this.dataSource.sort = this.sort;
          if (this.dataSource.data.length > 0) {
            this.dataSourceEmpty = false;
          }
        }
      }
    );
  }

  getPayoutDate() {
    let today = new Date();
    today.setDate(25);
    return this.formatDate(today);
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  formatYearAndMonth(date) {
    var d = new Date(date),
      month = '' + d.getMonth(),
      year = d.getFullYear();

    // If in January month needs to be set to december (12) and year to last year
    if (month == '0') {
      month = '12';
      year = year - 1;
    }

    if (month.length < 2) month = '0' + month;
    let yearMonth = [year, month].join('');
    return Number(yearMonth);
  }
}

class deviation {
  personId: string;
  yearAndMonth: number;
  provisions: [provision];
  deviationType: number;
}
class provision {
  sum: number;
  description: string;
}

import { Injectable } from '@angular/core';
import { Payout } from '../classes/Payout';

@Injectable({
  providedIn: 'root',
})
export class GlobalFunctions {
  employerFee = 0.3142;
  pensionTax = 0.2426;
  vacationPay = 0.12;

  public formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  public getTax(requestedType: string) {
    if (requestedType == 'sal_out') {
      return this.employerFee;
    } else {
      return this.pensionTax;
    }
  }

  public getVacationPay() {
    return this.vacationPay;
  }

  public getPensionTax() {
    return this.pensionTax;
  }

  public getEmployerFee() {
    return this.employerFee;
  }

  public increaseAmountWithTax(requestedType: string, amount: number) {
    var returnAmount = 0;
    if (requestedType == 'sal_out') {
      returnAmount = amount * (1 + this.getVacationPay());
      returnAmount = returnAmount * (1 + this.getTax(requestedType));
    } else {
      returnAmount = returnAmount * (1 + this.getTax(requestedType));
    }

    return returnAmount;
  }

  public getPayout(
    requestedType: string,
    amount: number,
    inputAmountIncludesFees: boolean
  ) {
    var tax = 0;
    var vacationPay = 0;
    var amountIncludingFees = 0;
    var amountExcludingFees = 0;

    if (inputAmountIncludesFees) {
      amountIncludingFees = amount;
      tax = amountIncludingFees * (1 - 1 / (1 + this.getTax(requestedType)));
      if (requestedType == 'sal_out') {
        vacationPay =
          (amountIncludingFees - tax) * (1 - 1 / (1 + this.getVacationPay()));
      }
      amountExcludingFees = amountIncludingFees - vacationPay - tax;
    } else {
      amountExcludingFees = amount;
      if (requestedType == 'sal_out') {
        vacationPay = amountExcludingFees * this.getVacationPay();
      }

      tax = (amountExcludingFees + vacationPay) * this.getTax(requestedType);
      amountIncludingFees = amountExcludingFees + vacationPay + tax;
    }
    return new Payout(
      amountIncludingFees,
      amountExcludingFees,
      tax,
      vacationPay
    );
  }
}

import { Injectable } from '@angular/core';
import { TransactionType } from '../../components/home/home.component';
import { User } from '../classes/User';
import { InternalProject } from '../classes/InternalProject';

@Injectable({
  providedIn: 'root',
})
export class GlobalVariables {
  public static TYPE_CAR: string = 'car';
  public static TYPE_SALARY_IN_EXT: string = 'sal_in_ext';
  public static TYPE_SALARY_IN_INT: string = 'sal_in_int';
  public static TYPE_PURCHASE: string = 'pur';
  public static TYPE_SALARY_OUT: string = 'sal_out';
  public static TYPE_PENSION_OUT: string = 'pen';

  public static USER_ROLE_ADMIN: string = 'Admin';
  public static USER_ROLE_MANAGER: string = 'Manager';
  public static USER_ROLE_USER: string = 'User';

  private _loggedInUser: User;
  private _loggedInUserRole: string;
  private _allUsers: User[] = [];
  private _internalProjects: InternalProject[] = [];
  private _payoutReqToApprove: number = 0;

  public get payoutReqToApprove(): number {
    return this._payoutReqToApprove;
  }
  public set payoutReqToApprove(value: number) {
    this._payoutReqToApprove = value;
  }

  public getPayoutReqToApprove() {
    return this._payoutReqToApprove;
  }

  public get internalProjects(): InternalProject[] {
    return this._internalProjects;
  }

  public set internalProjects(value: InternalProject[]) {
    this._internalProjects = value;
  }

  public get loggedInUserRole(): string {
    return this._loggedInUserRole;
  }
  public set loggedInUserRole(value: string) {
    this._loggedInUserRole = value;
  }

  public isLoggedinUserAdmin(): boolean {
    return this._loggedInUserRole == GlobalVariables.USER_ROLE_ADMIN;
  }

  public isLoggedinUserManager() {
    return (
      this._loggedInUserRole == GlobalVariables.USER_ROLE_ADMIN ||
      this._loggedInUserRole == GlobalVariables.USER_ROLE_MANAGER
    );
  }

  private _transactionTypes: TransactionType[] = [
    { value: '', icon_type: 'ico', icon: 'remove', viewValue: 'Alla' },
    {
      value: 'car',
      icon_type: 'ico',
      icon: 'directions_car',
      viewValue: 'Bil',
    },
    {
      value: 'sal_in_ext',
      icon_type: 'ico',
      icon: 'forward',
      viewValue: 'Fakturerat',
    },
    {
      value: 'pur',
      icon_type: 'ico',
      icon: 'shopping_basket',
      viewValue: 'Inköp',
    },
    {
      value: 'sal_out',
      icon_type: 'ico',
      icon: 'monetization_on',
      viewValue: 'Löneuttag',
    },
    {
      value: 'pen',
      icon_type: 'ico',
      icon: 'person',
      viewValue: 'Pensionsuttag',
    },
    {
      value: 'sal_in_int',
      icon_type: 'svg',
      icon: 'octopi',
      viewValue: 'Internt',
    },
  ];

  public get transactionTypes(): TransactionType[] {
    return this._transactionTypes;
  }
  public set transactionTypes(value: TransactionType[]) {
    this._transactionTypes = value;
  }

  public get allUsers(): User[] {
    return this._allUsers;
  }

  public set allUsers(value: User[]) {
    this._allUsers = value;
  }

  public get loggedInUser(): User {
    return this._loggedInUser;
  }
  public set loggedInUser(value: User) {
    this._loggedInUser = value;
  }

  private _selectedUser: User;
  public get selectedUser(): User {
    return this._selectedUser;
  }
  public set selectedUser(value: User) {
    this._selectedUser = value;
  }
  public get emptyUser() {
    var emptyUser: User;
    emptyUser = {
      user_mail: '',
      user_name: '',
      key: '',
      overtime: '',
      variable_salary: '',
      breaking_limit: '',
      factor: '',
      balance: '',
      harvest_id: '',
      equipment_reset_date: '',
      equipment_budget: '',
      wellness_budget: '',
      education_budget: '',
      car_leasing_fee: '',
      car_leasing_contribution: '0',
      special_deal_harvest_id: '',
      special_deal_rate: '',
    };
    return emptyUser;
  }
}

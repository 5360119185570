<div>
  <div class="topRow">
    <img class="logo" src="../../../assets/img/logo.png" />
    <div *ngIf="!productionEnv">DEVELOPMENT</div>
    <!--div>
      <button mat-raised-button (click)="onClickLogout()">Logout</button>
    </div-->
    <div class="headingWelcome" [title]="roles">
      Välkommen <span class="no-word-break">{{ getFirstName() }}</span
      >!
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Ersättning"> <app-home></app-home> </mat-tab>
    <mat-tab label="Dashboard"
      ><ng-template matTabContent><app-dashboard></app-dashboard></ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span
          [matBadge]="globalVariables.getPayoutReqToApprove()"
          [matBadgeHidden]="globalVariables.getPayoutReqToApprove() == 0"
          matBadgeOverlap="false"
          matBadgeColor="primary"
          >Begärda utbetalningar</span
        >
      </ng-template>
      <ng-template matTabContent><app-payout>sdsd</app-payout></ng-template>
    </mat-tab>
    <mat-tab *ngIf="globalVariables.isLoggedinUserAdmin()" label="Admin">
      <app-time-admin></app-time-admin>
    </mat-tab>
    <mat-tab *ngIf="globalVariables.isLoggedinUserAdmin()" label="Användare">
      <ng-template matTabContent><app-user-admin></app-user-admin></ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="chartContainer">
  <canvas
    baseChart
    width="400"
    height="400"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="barChartColors"
  >
  </canvas>
</div>

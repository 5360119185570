<ng-http-loader
  [backdrop]="false"
  [backgroundColor]="'#00ffd1'"
  [debounceDelay]="100"
  [extraDuration]="300"
  [minDuration]="300"
  [opacity]="0.6"
  [backdropBackgroundColor]="'#00ffd1'"
  spinner="sk-three-bounce"
></ng-http-loader>
<div style="margin: 10px">
  <div fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="20px">
    <div fxFlex="50">
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="20px">
        <div fxFlex="50">
          <app-mini-card
            type="wellness"
            title="Friskvårdsbidrag"
            icon="fitness_center"
            icon_color="#00ffd1"
          ></app-mini-card>
        </div>
        <div fxFlex="50">
          <app-mini-card
            type="education"
            title="Utbildning"
            icon="school"
            icon_color="#de7d77"
          ></app-mini-card>
        </div>
      </div>
    </div>
    <div fxFlex="50">
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="20px">
        <div fxFlex="50">
          <app-mini-card
            type="equipment"
            title="Kontorsutrustning"
            icon="devices"
            icon_color="#725871"
          ></app-mini-card>
        </div>
        <div fxFlex="50">
          <app-mini-card
            type="car"
            title="Förmånsbil"
            icon="directions_car"
            icon_color="#557e94"
          ></app-mini-card>
        </div>
      </div>
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutGap="20px"
    style="margin-top: 20px"
  >
    <mat-card
      style="background-color: #262626"
      class="dashboard-card"
      fxFlex="50"
    >
      <mat-card-header>
        <mat-card-title>Inbetalningar</mat-card-title>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
        <app-chart type="income"></app-chart>
      </mat-card-content>
    </mat-card>

    <mat-card
      style="background-color: #262626"
      class="dashboard-card"
      fxFlex="50"
    >
      <mat-card-header>
        <mat-card-title>Utbetalningar</mat-card-title>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
        <app-chart type="expenses"></app-chart>
      </mat-card-content>
    </mat-card>
  </div>
</div>

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: '4fba789d-b031-49dd-8576-67a68528a191',
  authority:
    'https://login.microsoftonline.com/8fc06086-c067-4245-8ed2-60247395fee8',
  redirectUrl: 'https://thebay.dev.octopibay.se/',
  sallaryManagerUrl: 'https://apim-salarymanager.azure-api.net/',
  subscriptionKey: '16431f04e04f4c70a0ac8b2af6937cda',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

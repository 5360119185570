import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/core/classes/User';
import { RestService } from 'src/app/core/services/rest.service';
import { GlobalVariables } from '../../core/global/globalvariables';
import { Wellness } from '../../core/classes/Wellness';
import { GlobalFunctions } from 'src/app/core/global/globalFunctions';
import { Education } from 'src/app/core/classes/Education';
import { Equipment } from 'src/app/core/classes/Equipment';

@Component({
  selector: 'app-benefit-admin',
  templateUrl: './benefit-admin.component.html',
  styleUrls: ['./benefit-admin.component.scss'],
})
export class BenefitAdminComponent implements OnInit {
  constructor(
    public globalVariables: GlobalVariables,
    private restService: RestService,
    public globalFunctions: GlobalFunctions,
    private snackBar: MatSnackBar
  ) {}

  selectedUser: User;
  selectedDate: Date;
  selectedType: String;

  benefitTypes: { [key: string]: string } = {
    wellness: 'Friskvård',
    education: 'Utbildning',
    equipment: 'Kontorsutrustning',
  };

  currentYear = new Date().getFullYear();

  benefit: {
    user: string;
    type: string;
    date: string;
    year: string;
    comment: string;
    amount: string;
  } = {
    user: '',
    type: '',
    date: '',
    year: '' + this.currentYear,
    comment: '',
    amount: '',
  };

  ngOnInit(): void {}

  onDateInput(event: any) {
    console.log(event.value);
    this.selectedDate = event.value;
    this.benefit.date = event.value;
  }

  onCreateTransaction() {
    //Format date
    let transactionDate = new Date(this.benefit.date);
    this.benefit.date = this.globalFunctions.formatDate(transactionDate);

    switch (this.benefit.type) {
      case 'wellness':
        var wellness: Wellness = {
          user_mail: this.benefit.user,
          transaction_date: this.benefit.date,
          year: this.benefit.year,
          comment: this.benefit.comment,
          amount: '' + this.benefit.amount,
          key: '',
        };

        this.restService
          .createWellness(wellness)
          .toPromise()
          .then(() => {
            this.openSnackBar();
          });
        break;
      case 'education':
        var education: Education = {
          user_mail: this.benefit.user,
          transaction_date: this.benefit.date,
          year: this.benefit.year,
          comment: this.benefit.comment,
          amount: '' + this.benefit.amount,
          key: '',
        };

        this.restService
          .createEducation(education)
          .toPromise()
          .then(() => {
            this.openSnackBar();
          });
        break;
      case 'equipment':
        var equipment: Equipment = {
          user_mail: this.benefit.user,
          transaction_date: this.benefit.date,
          comment: this.benefit.comment,
          amount: '' + this.benefit.amount,
          key: '',
        };

        this.restService
          .createEquipment(equipment)
          .toPromise()
          .then(() => {
            this.openSnackBar();
          });
        break;
    }
  }

  openSnackBar() {
    this.snackBar.open('Transaktionen skapad', 'OK', {
      duration: 2000,
      panelClass: ['mat-toolbar', 'mat-primary'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}

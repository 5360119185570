<mat-card class="dashboard-card">
  <mat-card-header class="card-header">
    <mat-card-title>
      <h1 class="card-title">{{ title }}</h1>
    </mat-card-title>
    <div mat-card-avatar class="card-icon">
      <mat-icon [style.color]="icon_color" class="avatar-icon">{{
        icon
      }}</mat-icon>
    </div>
  </mat-card-header>

  <mat-card-content>
    <!-- Wellness -->
    <div *ngIf="type == 'wellness'">
      <h2 class="amount-text">
        {{ wellnessBudget | number: "0.0-0":"se-SE" }} Kr
      </h2>
      <p class="description-text">
        <span class="duration">Kvar att utnyttja {{ currentYear }}</span>
      </p>
      <div
        *ngIf="!collapsed"
        [@expandableRow]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="transaction_date">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ element.transaction_date }}
            </td>
          </ng-container>
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              {{ element.comment }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount | number: "0.0-0":"se-SE" }}
            </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" style="text-align: center" colspan="9999">
              Inga transaktioner
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <!-- Education -->
    <div *ngIf="type == 'education'">
      <h2 class="amount-text">
        {{ educationBudget | number: "0.0-0":"se-SE" }} Kr
      </h2>
      <p class="description-text">
        <span class="duration">Kvar att utnyttja {{ currentYear }}</span>
      </p>
      <div
        *ngIf="!collapsed"
        [@expandableRow]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="transaction_date">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ element.transaction_date }}
            </td>
          </ng-container>
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              {{ element.comment }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount | number: "0.0-0":"se-SE" }}
            </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" style="text-align: center" colspan="9999">
              Inga transaktioner
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <!-- Equipment -->
    <div *ngIf="type == 'equipment'">
      <h2 class="amount-text">
        {{ equipmentBudget | number: "0.0-0":"se-SE" }} Kr
      </h2>
      <p class="description-text">
        <span class="duration">Kvar att utnyttja</span>
      </p>
      <div
        *ngIf="!collapsed"
        [@expandableRow]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="transaction_date">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ element.transaction_date }}
            </td>
          </ng-container>
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              {{ element.comment }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount | number: "0.0-0":"se-SE" }}
            </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" style="text-align: center" colspan="9999">
              Inga transaktioner
            </td>
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <p class="equipment-reset-date">
          Ny pott
          {{ equipmentResetDate | dateTimeFormatFilter: "YYYY-MM-DD" }}
        </p>
      </div>
    </div>
    <!-- Car -->
    <div *ngIf="type == 'car'">
      <!-- Has a car -->
      <div *ngIf="hasCar == true">
        <h2 class="amount-text">{{ carCost | number: "0.0-0":"se-SE" }} Kr</h2>
        <p class="description-text">
          <span class="duration">Leasingavgift per månad</span>
        </p>
        <div
          *ngIf="!collapsed"
          [@expandableRow]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <table
            style="
              margin-top: 10px;
              margin-bottom: 0px;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <tr>
              <td style="text-align: right; padding-right: 10px">
                <span class="duration">Leasingavgift: </span>
              </td>
              <td style="text-align: right">
                <span>{{ carLeasingFee | number: "0.0-0":"se-SE" }} Kr</span>
              </td>
            </tr>
            <tr>
              <td
                style="
                  text-align: right;
                  padding-right: 10px;
                  border-bottom: solid 1px;
                "
              >
                <span class="duration">Leasingbidrag: </span>
              </td>
              <td style="text-align: right; border-bottom: solid 1px">
                <span>{{ -carContribution | number: "0.0-0":"se-SE" }} Kr</span>
              </td>
            </tr>
            <tr style="margin-bottom: 0px">
              <td
                style="
                  padding-bottom: 0px;
                  text-align: right;
                  padding-right: 10px;
                "
              >
                <span class="duration">Du betalar: </span>
              </td>
              <td style="text-align: right">
                <span>{{ carCost | number: "0.0-0":"se-SE" }} Kr</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Does not have a car -->
      <div *ngIf="hasCar == false" style="padding-bottom: 48px">
        <h3 class="amount-text" style="padding-top: 8px">
          Du har ingen förmånsbil
        </h3>
      </div>
    </div>

    <!-- Vacation -->
    <div *ngIf="type == 'vacation'">
      <h2 class="amount-text">{{ vacationTotal }} dagar</h2>
      <p class="description-text">
        <span class="duration">Kvar att ta ut</span>
      </p>
      <div
        *ngIf="!collapsed"
        [@expandableRow]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <table
          style="
            width: 100%;
            margin-top: 10px;
            margin-bottom: 0px;
            text-align: center;
          "
        >
          <tr>
            <td>
              <span class="duration">Betalda: </span>
              <span>{{ vacationPaid }} dagar</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="duration">Sparade: </span>
              <span>{{ vacationSaved }} dagar</span>
            </td>
          </tr>
          <tr style="margin-bottom: 0px">
            <td style="padding-bottom: 0px">
              <span class="duration">Obetalda: </span>
              <span>{{ vacationUnpaid }} dagar</span>
            </td>
          </tr>
        </table>
        <p class="equipment-reset-date">
          Uppdaterat tom
          {{ vactionUpdatedUntil | dateTimeFormatFilter: "YYYY-MM-DD" }}
        </p>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="(type == 'car' && hasCar == true) || type != 'car'">
    <button
      mat-icon-button
      (click)="collapsed ? (collapsed = false) : (collapsed = true)"
    >
      <mat-icon class="expand-icon" matTooltip="Expandera" *ngIf="collapsed"
        >expand_more</mat-icon
      >
      <mat-icon class="expand-icon" matTooltip="Minimera" *ngIf="!collapsed"
        >expand_less</mat-icon
      >
    </button>
  </mat-card-actions>
</mat-card>
